import { doRequest, getRequestUrl, getQueryParams, getRequestHeaders, getRequestBody } from './utils/request.js';
import { ServiceError } from './utils/errors.js';

const getUrl = ({road, locality, province, country, limit = 50}) => {
	let url = getRequestUrl('roads', '');
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	if (MOCKS) {
		return url;
	}
	return (url + getQueryParams({
		query: road,
		locality,
		province,
		country,
		limit
	}));
};

const getMethod = () => {
	return 'GET';
};

const getHeaders = () => {
	return getRequestHeaders();
};

const getBody = (body) => {
	return getRequestBody(body);
};

const getData = ({road, locality, limit}) => {
	const url = getUrl({road, locality, limit});
	return {
		url: url,
		method: getMethod(),
		headers: getHeaders(),
		body: getMethod() === 'GET' ? null : getBody(),
		preParse: (response) => _preParse(url, response)
	};
};

const checkErrors = (response) => {
	if (response && response.code) {
		switch(response.code) {
		default:
			throw new ServiceError(response);
		}
	}
	return response;
};

const _preParse = (url, response) => {
	response.results = response.results.map((item) => {
		item.fullName = `${item.type} ${item.name}`;
		return item;
	});

	response.count = response.results.length;
	return response;
};

export const getRoads = ({road, locality, limit}) => {
	const { url, method, headers, body, preParse} = getData({road, locality, limit});
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};
