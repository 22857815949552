export const INVALID_DATA = 'invalid_data';
export const UNEXPECTED_ERROR = 'unexpected_error';
export const NOT_AVAILABLE_FOR_USER = 'not_available_for_user';
export const USER_NOT_EXISTS = 'user_not_exists';
export const USER_ALREADY_REGISTERED = 'user_already_registered';
export const USER_BLOCKED = 'user_blocked';
export const USER_INVALID_PASSWORD = 'user_invalid_password';
export const INVALID_PIN = 'invalid_pin';
export const EXPIRED_PIN = 'expired_pin';
export const LOCATION_NOT_EXISTS = 'location_not_exists';
export const LOCATION_NOT_AVAILABLE_FOR_THE_DAY = 'location_not_available_for_the_day';
export const LOCATION_NOT_AVAILABLE_FOR_THE_SERVICE = 'location_not_available_for_the_service';
export const LOCATION_NOT_AVAILABLE_FOR_CUSTOMER = 'location_not_available_for_customer';
export const LOCATION_NOT_AVAILABLE_FOR_PROFESSIONAL = 'location_not_available_for_professional';
export const LOCKER_NOT_AVAILABLE = 'locker_not_available';
export const REFUND_NOT_AVAILABLE = 'refund_not_available';
export const TRANSACTION_NOT_AVAILABLE = 'transaction_not_available';
export const TRANSACTION_NOT_FOUND = 'transaction_not_found';
export const PAYMENT_VERY_SMALL = 'payment_very_small';
export const PAYMENT_FAILED = 'payment_failed';
export const COUPON_NOT_FOUND = 'coupon_not_found';
export const DATETIME_IN_THE_PAST = 'datetime_in_the_past';
export const EMAIL_ALREADY_REGISTERED = 'email_already_registered';
export const LOCKER_NOT_EXIST = 'locker_not_exists';
export const LOCKER_ALREADY_INSTALLED = 'locker_already_installed';
export const LOCK_NOT_EXISTS = 'lock_not_exists';
export const LOCATION_ALREADY_INSTALLED = 'location_already_installed';
export const LOCK_IS_NOT_AUTOLOCK = 'lock_is_not_autolock';
export const LOCK_ALREADY_INSTALLED = 'lock_already_installed';
export const UNAUTHORIZED = 'Unauthorized';
export const NOT_AUTHENTICATED = 'not_authenticated';
export const PERMISSION_DENIED = 'permission_denied';
export const UNKNOWN = '';

export class ServiceError extends Error {
	constructor({message, code, detail}) {
		super(message);
		this.name = code;
		this.code = code;
		this.detail = detail;
	}
}
