import { timeOut } from '@polymer/polymer/lib/utils/async.js';
import { Debouncer } from '@polymer/polymer/lib/utils/debounce.js';
import { track } from '../services/tracking.js';

export const GET_ROADS = 'GET_ROADS';
export const GET_ROADS_FAIL = 'GET_ROADS_FAIL';
export const SET_ROADS = 'SET_ROADS';
export const SELECT_ROAD = 'SELECT_ROAD';
export const SET_ROAD_NAME = 'SET_ROAD_NAME';
export const SET_ROAD_NUMBER = 'SET_ROAD_NUMBER';
export const RESET_ADDRESS = 'RESET_ADDRESS';
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_FAIL = 'GET_ROADS_FAIL';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const SELECT_LOCALITY = 'SELECT_LOCALITY';

import { selectedRoadSelector, localitySelector } from '../reducers/locationSearch';
import { getRoads as getRoadsService } from '../services/roads.js';
import { getAddresses as getAddressesService } from '../services/addresses.js';
import { handleProspectionError, showSnackbar } from './app.js';

export const STORED_LOCALITY = 'STORED_LOCALITY';
const STREET_MIN_LENGTH = 3;
const STREET_NUMBER_MIN_LENGTH = 1;
const MAX_SUGGESTIONS = 10;
const DEBOUNCE_TIMER = 700;
let _debounceGetRoadsService;
let _debounceGetAddressesService;

export const getRoads = ({roadName, locality}) => (dispatch) => {
	dispatch({
		type: GET_ROADS,
		roadName
	});
	if (roadName && roadName.length >= STREET_MIN_LENGTH ) {
		_debounceGetRoadsService = Debouncer.debounce(
			_debounceGetRoadsService,
			timeOut.after(DEBOUNCE_TIMER),
			() => {
				getRoadsService({ road: roadName.toLowerCase(), locality, limit: MAX_SUGGESTIONS })
					.then((response) => dispatch(getRoadsSucceeded(response)))
					.catch((error) => dispatch(getRoadsFailed(error)));
			}
		);
	} else {
		dispatch(getRoadsSucceeded({results:[]}));
	}
};

export const setRoadName = ({roadName}) => (dispatch, getState) => {
	dispatch({
		type: SET_ROAD_NAME,
		roadName
	});
	dispatch(getRoads({roadName, locality: localitySelector(getState())}));
};

export const setRoadNumber = ({roadNumber}) => (dispatch, getState) => {
	dispatch({
		type: SET_ROAD_NUMBER,
		roadNumber
	});
	dispatch(getAddresses({
		roadNumber,
		road: selectedRoadSelector(getState())
	}));
};

export const setRoads = (roads) => ({
	type: SET_ROADS,
	roads: roads
});

export const roadSelected = (road) => ({
	type: SELECT_ROAD,
	selectedRoad: road
});

export const localitySelected = (locality) => (dispatch) => {
	if (locality) {
		localStorage.setItem(STORED_LOCALITY, locality);
	} else {
		locality = localStorage.getItem(STORED_LOCALITY);
	}
	dispatch({
		type: SELECT_LOCALITY,
		locality
	});
};

export const getRoadsSucceeded = (response) => (dispatch) => dispatch(setRoads(response.results));
export const getRoadsFailed = (error) => (dispatch) => {
	if (error.code) {
		dispatch(handleProspectionError(error));
	} else {
		track({ category: 'error', action: 'Unable to reach server' });
		dispatch(showSnackbar('No se pudo acceder a los datos'));
	}
};

export const resetAddress = () => (dispatch) => {
	dispatch({ type: RESET_ADDRESS });
	dispatch(localitySelected());
};


export const getAddresses = ({road, roadNumber}) => (dispatch) => {
	if (road && road.id && roadNumber && roadNumber.length >= STREET_NUMBER_MIN_LENGTH ) {
		dispatch({
			type: GET_ADDRESSES,
			roadNumber
		});
		_debounceGetAddressesService = Debouncer.debounce(
			_debounceGetAddressesService,
			timeOut.after(DEBOUNCE_TIMER),
			() => getAddressesService({ roadId: road.id, startsWith: roadNumber, limit: MAX_SUGGESTIONS })
				.then((response) => dispatch(getAddressesSucceeded(response)))
				.catch((error) => dispatch(getAddressesFailed(error)))
		);

	} else {
		dispatch(getAddressesSucceeded({results:[]}));
	}
};

export const setAddresses = (addresses) => ({
	type: SET_ADDRESSES,
	addresses: addresses
});

export const addressSelected = (address) => ({
	type: SELECT_ADDRESS,
	selectedAddress: address
});

export const getAddressesSucceeded = (response) => (dispatch) => dispatch(setAddresses(response.results));
export const getAddressesFailed = (error) => (dispatch) => {
	if (error.code) {
		dispatch(handleProspectionError(error));
	} else {
		dispatch(showSnackbar('No se pudo acceder a los datos'));
	}
};
