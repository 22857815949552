import { doRequest, getRequestUrl, getQueryParams, getRequestHeaders, getRequestBody, queryObject } from './utils/request.js';
import { ServiceError } from './utils/errors.js';

const getUrl = ({id, ids, roadId, startsWith}) => {
	let url = getRequestUrl('addresses');
	if (id) {
		url += `/${id}`;
	} else {
		const queryParams = {
			ids: ids,
			road_id: roadId,
			starts_with: startsWith,
		};
		url += getQueryParams(queryParams);
	}
	return url;
};

const getHeaders = () => {
	return getRequestHeaders();
};

const getBody = (body) => {
	return getRequestBody(body);
};

const getData = ({id, ids, roadId, startsWith}, method, body) => {
	const url = getUrl({id, ids, roadId, startsWith});
	method = method || 'GET';
	return {
		url,
		method,
		headers: getHeaders(),
		body: method === 'GET' ? null : getBody(body),
		preParse: (response) => _preParse(url, response)
	};
};

const getLocationSaleData = ({id, ids}, method, body) => {
	const data = getData({id, ids}, method, body);
	data.url += '/location-sale';
	return data;
};

const checkErrors = (response) => {
	if (response && response.code) {
		switch(response.code) {
		default:
			throw new ServiceError(response);
		}
	}
	return response;
};

const _preParse = (url, response) => {
	var search = new URL(url).search;
	const { ids, road_id, starts_with } = queryObject(search);

	if (ids) {
		const idsArr = ids.split(',');
		response.results = response.results
			.filter((item) => idsArr.indexOf(item.id) >= 0);
	} else {
		response.results = response.results
			.filter((item) => item.road_id === road_id)
			.filter((item) => item.number.startsWith(starts_with));
	}

	response.count = response.results.length;
	return response;
};

export const getAddresses = ({ids, roadId, startsWith, limit}) => {
	const { url, method, headers, body, preParse} = getData({ids, roadId, startsWith, limit});
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};

export const putAddress = (address) => {
	const method = 'PUT';
	const { url, headers, body, preParse} = getData({id: address.id}, method, address);
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};

export const postAddressLocationSales = (id) => {
	const method = 'POST';
	const { url, headers, body, preParse} = getLocationSaleData({id}, method);
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};
