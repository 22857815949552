import { createSelector } from 'reselect';
import RegExpCreator from '../tools/regexpcreator.js';
import {
	RESET_ADDRESS,
	GET_ROADS,
	SET_ROADS,
	SELECT_ROAD,
	SET_ADDRESSES,
	SELECT_ADDRESS,
	GET_ROADS_FAIL,
	GET_ADDRESSES_FAIL,
	SET_ROAD_NAME,
	SET_ROAD_NUMBER,
	SELECT_LOCALITY
} from '../actions/locationSearch.js';

const localities = [
	"Alcalá de Henares", 
	"Alcobendas", 
	"Alcorcón", 
	"Boadilla del Monte", 
	"Boulogne Billancourt", 
	"Clichy", 
	"Coslada", 
	"Fuenlabrada", 
	"Getafe", 
	"Issy Les Moulineaux", 
	"Las Rozas", 
	"Leganés", 
	"Levallois Perret", 
	"Madrid", 
	"Majadahonda", 
	"Montrouge", 
	"Mostoles", 
	"Neuilly Sur Seine", 
	"Paris", 
	"Pozuelo de Alarcón", 
	"Puteaux", 
	"Rivas Vaciamadrid", 
	"San Fernando de Henares", 
	"San Sebastián de los Reyes", 
	"St Cloud", 
	"Suresnes", 
	"Torrejón de Ardoz", 
	"Tres cantos", 
	"Valdemoro", 
	"Vanves"
];

const getInitialState = () => {
	return {
		localities,
		locality: '',
		roadName: '',
		roadNumber: '',
		roads: [],
		selectedRoad: {},
		addresses: [],
		error: ''
	};
};

const locationSearch = (state = getInitialState(), action) => {
	switch (action.type) {

		case RESET_ADDRESS:
			return {
				...getInitialState()
			};
		case SET_ROAD_NAME:
			return {
				...state,
				roadName: action.roadName,
				roadNumber: '',
				selectedRoad: {}
			};
		case SET_ROAD_NUMBER:
			return {
				...state,
				roadNumber: action.roadNumber
			};
		case GET_ROADS:
			return {
				...state,
			};
		case SET_ROADS:
			return {
				...state,
				roads: action.roads
			};
		case SELECT_ROAD:
			return {
				...state,
				roads: [],
				selectedRoad: action.selectedRoad,
				roadName: action.selectedRoad ? `${action.selectedRoad.type} ${action.selectedRoad.name}` : state.roadName
			};
		case SET_ADDRESSES:
			return {
				...state,
				addresses: action.addresses
			};
		case SELECT_ADDRESS:
			return {
				...state,
				addresses: [],
				selectedAddress: action.selectedAddress,
				roadNumber: action.selectedAddress ? action.selectedAddress.number : ''
			};
		case SELECT_LOCALITY:
			return {
				...state,
				locality: action.locality,
				roadName: '',
				roadNumber: '',
				selectedRoad: {},
				roads: [],
				addresses: [],
			};
		case GET_ROADS_FAIL:
		case GET_ADDRESSES_FAIL:
			return {
				...state,
				error: action.error
			};

		// as always, on default do nothing
		default:
			return state;
	}
};

export default locationSearch;

const roadFullName = (road) => {
	return road.type && road.name ? `${road.type} ${road.name}` : null;
};

const fullNumberInfo = (address) => {
	return address.number + (address.additional_information ? ` ${address.additional_information}` : '');
};

const hightlightText = (text, query) => text.replace(query, '<strong>$2</strong>');

export const localitiesSelector = state => state.locationSearch.localities;
export const localitySelector = state => state.locationSearch.locality;
export const roadNameSelector = state => state.locationSearch.roadName;
export const roadNumberSelector = state => state.locationSearch.roadNumber;
export const roadsSelector = state => state.locationSearch.roads;
export const selectedRoadSelector = state => state.locationSearch.selectedRoad;
export const selectedAddressSelector = state => state.locationSearch.selectedAddress;
export const addressesSelector = state => state.locationSearch.addresses;

export const roadNameSuggestions = createSelector(
	roadsSelector,
	roadNameSelector,
	(roads, roadName) => {
		if (roadName) {
			let queryRegExp = new RegExpCreator().create(roadName);
			roads.forEach((road) => {
				road.__suggestion = hightlightText(roadFullName(road), queryRegExp);
			});
		}
		return roads;
	}
);

export const roadNumberSuggestions = createSelector(
	addressesSelector,
	roadNumberSelector,
	(addresses, number) => {
		if (number) {
			let queryRegExp = new RegExpCreator().create(number);
			addresses.forEach((address) => {
				address.__suggestion = hightlightText(fullNumberInfo(address), queryRegExp);
			});
		}
		return addresses;
	}
);

export const selectedRoadIdSelector = createSelector(
	selectedRoadSelector,
	(road) => road && road.id
);

export const selectedAddressIdSelector = createSelector(
	selectedAddressSelector,
	(address) => address && address.id
);

export const additionInfoSelector = createSelector(
	selectedAddressSelector,
	(address) => address && address.additional_information || ''
);

export const postalCodeSelector = createSelector(
	selectedAddressSelector,
	(address) => address && address.postal_code || ''
);

export const provinceSelector = createSelector(
	selectedAddressSelector,
	(address) => address && address.province || ''
);
