import { getProspection, resetProspection, setProspection, getProspectionSucceeded, saveContactPeople, addContract } from './prospection.js';

import { getLocationSaleByAddress, putLocationSale } from '../services/locationSales';
import { postAddressLocationSales } from '../services/addresses';
import { getAuthData, setAuthData} from '../services/utils/auth';
import { getPlacementPictures } from '../services/placementPictures';
import { resetAddress } from './locationSearch';
import { selectedAddressIdSelector } from '../reducers/locationSearch';
import { track, trackPage } from '../services/tracking';

export const APP_ERROR = 'APP_ERROR';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_OFFLINE = 'UPDATE_OFFLINE';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const UPDATE_PAGE_COMPLETED = 'UPDATE_PAGE_COMPLETED';
export const UPDATE_MEETS_REQUIREMENTS = 'UPDATE_MEETS_REQUIREMENTS';
import { NOT_AUTHENTICATED, PERMISSION_DENIED, INVALID_DATA } from '../services/utils/errors';
export const STEPS = ['building', 'features', 'space', 'connections', 'contacts', 'board', 'agent', 'signature' ];
export const MAIN_PAGES = ['building', 'logout', 'error', 'success', 'dismiss'];
const DEFAULT_PAGE = MAIN_PAGES[0];
const SNACKBAR_TIMEOUT = 3000;

export const handleProspectionError = (error) => (dispatch) => {
	let appError;
	switch(error.code) {
		case NOT_AUTHENTICATED:
			track({ category: 'error', action: 'Not authenticated' });
			dispatch(logout());
			break;
		case PERMISSION_DENIED:
			track({ category: 'error',  action: 'Permission denied' });
			appError = {
				title: 'No tienes acceso a esta ubicación',
				message: 'esta dirección no está asignada a tu empresa.'
			};
			break;
		case INVALID_DATA:
			if (error.detail && 
				error.detail.phone_number &&
				error.detail.phone_number.includes('The phone number cannot be parsed.')) {
				track({ category: 'error',  action: 'Phone number cannot be parsed' });
				appError = {
					title: 'El número de teléfono tiene un formato no admitido',
					message: 'asegúrate de que incluye el código internacional (ej: +34) y no le falta ningún dígito.'
				};
			} else if (error.detail && 
				error.detail.phone_number &&
				error.detail.phone_number.includes('The phone number is not valid.')) {
				track({ category: 'error',  action: 'Phone number is not valid' });
				appError = {
					title: 'El número de teléfono no es válido',
					message: 'asegúrate de que el número introducido es auténtico y no le falta ningún dígito.'
				};
			} else {
				track({ category: 'error',  action: 'Generic invalid data' });
				appError = {
					title: 'Alguno de los datos que has enviado no son válidos',
					message: 'asegúrate de que has completado todos los campos con valores admitidos.'
				};
			}
			break;
		default:
			track({ category: 'error',  action: 'Unexpected error' });
			appError = {
				title: 'Error inesperado',
				message: 'se ha producido un error inesperado.'
			};
			break;
	}

	if (appError) {
		dispatch({
			type: APP_ERROR,
			...appError,
		});
		dispatch(goTo('error'));
	}
};

function getQueryParams(queryString = window.location.search.substring(1)) {
	return queryString.split('&')
		.map((str) => {
			const [key, value] = str.split('=');
			return {
				[key]: decodeURI(value)
			};
		})
		.reduce((prev, curr) => Object.assign(prev, curr));
}

function getToken() {
	const { token } = getQueryParams();
	if (token) {
		setAuthData({ token });
	}
	const authData = getAuthData();
	return authData && authData.token;
}

export const init = () => (dispatch) => {
	const token = getToken();
	if (token) {
		dispatch({
			type: UPDATE_TOKEN,
			token: token
		});
		history.replaceState({}, '', location.pathname);
	} else {
		dispatch(login());
	}
};

export const navigate = (path) => (dispatch, getState) => {
	const parts = cleanPath(path).split('/');
	let page;
	switch(parts.length) {
	case 3:
		if(DEFAULT_PAGE === parts[0]) {
			if(STEPS.indexOf(parts[2]) > 0 ) {
				page = parts[2];
				const id = parts[1];
				const state = getState();
				if (!state.prospection || id !== state.prospection.current.id) {
					dispatch(setProspection({id}));
					dispatch(getProspection());
				}
			} else {
				dispatch(goToHome());
			}
		} else {
			page = parts[0];
			dispatch(goTo(`/${parts[0]}`));
		}
		break;
	case 2:
		dispatch(goToHome());
		break;
	default:
		if(MAIN_PAGES.indexOf(parts[0]) >= 0) {
			page = parts[0];
		} else {
			page = '';
			dispatch(goToHome());
		}
	}
	dispatch(loadPage(page));
};

const goTo = (path) => (dispatch) => {
	history.pushState({}, '', path);
	dispatch(navigate(path));
};

export const goToHome = () => (dispatch) => {
	const path = `/${DEFAULT_PAGE}`;
	dispatch(saveProspection(() => {
		dispatch(resetAddress());
		dispatch(resetProspection());
		dispatch(goTo(path));
	}));
};

const gotToFirstStep = (id) => (dispatch, getState) => {
	const { steps } = getState().app;
	const path = `/${DEFAULT_PAGE}/${id}/${steps[1]}`;
	dispatch(goTo(path));
};

const cleanPath = (path) => {
	if (path.charAt(path.length-1) === '/') {
		path = path.slice(0, length-1);
		history.pushState({}, '', path);
	}
	return path.charAt(0) === '/' ? path.slice(1) : path;
};

const loadPage = (page) => (dispatch) => {
	switch(page) {
	case 'logout':
		dispatch(logout());
		break;
	case 'features':
		import('../pages/crm-prospection-features/crm-prospection-features.js');
		break;
	case 'space':
		import('../pages/crm-prospection-space/crm-prospection-space.js');
		break;
	case 'connections':
		import('../pages/crm-prospection-connections/crm-prospection-connections.js');
		break;
	case 'contacts':
		import('../pages/location-contacts/location-contacts.js');
		break;
	case 'agent':
		import('../pages/crm-prospection-agent/crm-prospection-agent.js');
		break;
	case 'board':
		import('../pages/crm-prospection-board/crm-prospection-board.js');
		break;
	case 'signature':
		import('../pages/crm-prospection-signature/crm-prospection-signature.js');
		break;
	case 'success':
		import('../pages/crm-prospection-success/crm-prospection-success.js');
		break;
	case 'error':
		import('../pages/crm-prospection-error/crm-prospection-error.js');
		break;
	case 'dismiss':
		import('../pages/crm-prospection-dismiss.js');
		break;
	default:
		page = DEFAULT_PAGE;
		import('../pages/cbp-building/cbp-building.js');
		break;
	}
	trackPage(page);
	dispatch(updatePage(page));
};

const updatePage = (page) => {
	return {
		type: UPDATE_PAGE,
		page,
		isMainPage: MAIN_PAGES.indexOf(page) >= 0
	};
};

export const updatePageCompleted = (isPageCompleted) => {
	return {
		type: UPDATE_PAGE_COMPLETED,
		isPageCompleted: isPageCompleted,
	};
};

export const updateMeetsRequirements = ({ meetsRequirements, message }) => (dispatch) => {
	dispatch({
		type: UPDATE_MEETS_REQUIREMENTS,
		meetsRequirements
	});
	if (!meetsRequirements) {
		dispatch({
			type: APP_ERROR,
			title: 'Esta ubicación no cumple con los requisitos',
			message
		});
	}
};

let snackbarTimer;

export const showSnackbar = (snackbarMessage) => (dispatch) => {
	dispatch({
		type: OPEN_SNACKBAR,
		snackbarMessage
	});
	clearTimeout(snackbarTimer);
	snackbarTimer = setTimeout(() => dispatch({ type: CLOSE_SNACKBAR }), SNACKBAR_TIMEOUT);
};

export const updateOffline = (offline) => (dispatch, getState) => {
	if (getState().app.offline !== undefined) {
		if (offline === true) {
			dispatch(showSnackbar('Se ha perdido la conexión'));
		}
		if (offline === false) {
			dispatch(showSnackbar('Se ha recuperado la conexión'));
		}
	}
	dispatch({
		type: UPDATE_OFFLINE,
		offline
	});
};

export const updateLayout = () => () => {

};

export const login = () => () => {
	window.location = `${window.process.env.CITIBOX_AUTH_URL}?callbackUrl=${location.href}&client=${window.process.env.CITIBOX_CLIENT_ID}`;
};

export const logout = () => (dispatch) => {
	track({ category: 'click', action: 'Log out'});
	setAuthData({});
	dispatch(showSnackbar('Tu sesión ha caducado'));
	dispatch({
		type: UPDATE_TOKEN,
		token: null
	});
	window.location = `${window.process.env.CITIBOX_AUTH_URL}/logout?callbackUrl=${location.origin}&client=${window.process.env.CITIBOX_CLIENT_ID}`;
};

const getStepPath = (step, steps, locationSaleId) => {
	if (step && locationSaleId) {
		return `/${DEFAULT_PAGE}/${locationSaleId}/${steps[step]}`;
	} else {
		return `/${DEFAULT_PAGE}`;
	}
};

export const prevStep = () => (dispatch, getState) => {
	const state = getState();
	const {
		steps,
		step,
	} = state.app;
	const { current } = state.prospection;
	let onSave;
	const nextStep = step - 2;
	if (nextStep > 0) {
		const path = getStepPath(nextStep, steps, current.id);
		onSave = () => {
			dispatch(getProspection());
			dispatch(goTo(path));
		};
	} else {
		onSave = () => dispatch(goToHome());
	}
	dispatch(saveProspection(onSave));
};

const initProspection = (prospection) => (dispatch) => {
	dispatch(getProspectionSucceeded(prospection));
	dispatch(gotToFirstStep(prospection.id));
	return prospection;
};

const saveProspection = (callback) => (dispatch, getState) => {
	let promises;
	const state = getState();
	const { current } = state.prospection;
	if (current && current.id) {
		promises = Promise.all([
			saveContactPeople(current),
			putLocationSale(current)
		]);
	} else {
		promises = Promise.resolve();
	}
	return promises
		.then(callback)
		.catch((error) => {
			dispatch(handleProspectionError(error));
		});
};

export const goToDismiss = () => (dispatch, getState) => {
	const state = getState();
	const { meetsRequirements } = state.app;
	if (meetsRequirements) {
		dispatch(saveProspection(() => dispatch(goTo('dismiss'))));
	} else {
		dispatch(goTo('dismiss'));
	}
};

export const goBack = () => (dispatch, getState) => {
	const state = getState();
	const {
		steps,
		lastStep
	} = state.app;
	const { current } = state.prospection;
	const path = getStepPath(lastStep - 1, steps, current.id);
	dispatch(goTo(path));
};

export const startSteps = () => (dispatch, getState) => {
	const addressId = selectedAddressIdSelector(getState());
	if (addressId) {
		getLocationSaleByAddress(addressId)
			.then((response) => {
				if (response.results && response.results.id) {
					return dispatch(initProspection(response.results));
				} else {
					return postAddressLocationSales(addressId)
						.then((response) => dispatch(initProspection(response)));
				}
			})
			.then((locationSale) => getPlacementPictures({ locationSale }))
			.catch((error) => dispatch(handleProspectionError(error)));

	} else {
		dispatch(goToHome());
	}
};

export const nextStep = () => (dispatch, getState) => {
	const state = getState();
	const {
		meetsRequirements,
		steps,
		step,
	} = state.app;
	const { current } = state.prospection;
	let onSave;
	if (meetsRequirements) {
		const path = (step < steps.length) ?  getStepPath(step, steps, current.id) : 'success';
		onSave = () => {
			dispatch(getProspection());
			dispatch(goTo(path));
		};
	} else {
		const path = 'error';
		onSave = () => dispatch(goTo(path));
	}
	dispatch(saveProspection(onSave));
};

const uploadEmptyContract = () => (dispatch) => {
	const emptyPdfFile = new Blob(['Esta ubicación no requiere un contrato firmado'], { type: 'application/pdf' });
	emptyPdfFile.lastModifiedDate = +new Date();
	emptyPdfFile.name = 'contrato-en-blanco.pdf';
	track({ category: 'click', action: 'Upload empty contract' });
	dispatch(addContract(emptyPdfFile));
};
