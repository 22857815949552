import { getAuthData } from './auth.js';
import {
	INVALID_DATA,
	UNEXPECTED_ERROR,
	NOT_AUTHENTICATED,
	PERMISSION_DENIED
} from './errors.js';

export const queryString = (obj) => {
	return obj && Object.keys(obj)
		.filter((key) => typeof obj[key] !== 'object' && typeof obj[key] !== 'undefined')
		.map((key) => `${key}=${encodeURIComponent(obj[key])}`)
		.join('&');
};

export const queryObject = (string) => {
	if (string.charAt(0) === '?') {
		string = string.slice(1);
	}
	const pairs = string.split('&');
	var result = {};
	pairs.forEach(function(pair) {
		pair = pair.split('=');
		result[pair[0]] = decodeURIComponent(pair[1] || '');
	});
	return JSON.parse(JSON.stringify(result));
};


const _parseJSON = (response) => {
	return response.text().then((text) => {
		let result = null;
		if (text) {
			try {
				result = JSON.parse(text);
			} catch(error) {
				result = {
					code: UNEXPECTED_ERROR,
					status: response.status,
					message: response.statusText
				};
			}
		}
		return result;
	});
};

const _checkErrors = (response) => {
	if (response && response.code) {
		return Promise.reject(response);
	}
	return response;
};

export const getRequestUrl = (apiMethod, id) => {
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	let url = MOCKS ? `${location.origin}/mocks/${apiMethod}.json` : `https://${CITIBOX_API_HOST}/app_service/crm/v1/${apiMethod}`;

	if (id && MOCKS) {
		const idPArts = id.split('-');
		id = idPArts[idPArts.length - 1];
		url = url.replace('.json', `-${id}.json`);
	}

	if (id && !MOCKS){
		url += `/${id}`;
	}

	return url;
};

export const getQueryParams = (queryParams) => {
	const params = queryString(queryParams);
	return params ? `?${params}` : '';
};

export const getRequestHeaders = (headers) => {
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	const authData = getAuthData();
	const token = authData && authData.token;
	const authorization = token ? {'Authorization': `Bearer ${token}` } : {};
	if (MOCKS) {
		return {
			'Content-Type': 'application/json',
			'accept': 'application/json',
		};
	}
	return {
		'Content-Type': 'application/json',
		'accept': 'application/json',
		...authorization,
		...headers
	};
};

export const getRequestBody = (body) => {
	return JSON.stringify(body);
};

export const doRequest = (url, method, headers, body, preParse) => {
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');

	let promise = fetch(url, { method: MOCKS ? 'GET' : method, headers, body: MOCKS ? undefined : body });
	promise = promise
		.then(_parseJSON)
		.then(_checkErrors);
		
	if (MOCKS) {
		if (method === 'PUT') {
			preParse = () => null;
		}
		preParse = preParse || ( (response) => response);
		promise = promise.then(preParse);
	}
	return promise;
};
