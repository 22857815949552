import { css } from 'lit-css';

export default css`
@keyframes lava-bubble-animation {
	0% {
		transform: skew(8deg, 10deg);
	}
	25% {
		transform: skew(5deg, -8deg);
	}
	50% {
		transform: skew(-10deg, -8deg);
	}
	75% {
		transform: skew(6deg, -5deg);
	}
	100% {
		transform: skew(8deg, 10deg);
	}
}

@-webkit-keyframes lava-bubble-animation {
	0% {
		-webkit-transform: skew(8deg, 10deg);
	}
	25% {
		-webkit-transform: skew(5deg, -8deg);
	}
	50% {
		-webkit-transform: skew(-10deg, -8deg);
	}
	75% {
		-webkit-transform: skew(6deg, -5deg);
	}
	100% {
		-webkit-transform: skew(8deg, 10deg);
	}
}`;
