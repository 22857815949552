import { doRequest, getRequestUrl, getRequestHeaders } from './utils/request.js';
import { ServiceError } from './utils/errors.js';

export const uploadPlacementPictures = ({ locationSale, pictures }) => {
	const method = 'POST';
	const { url, headers, body } = getData({ locationSale, pictures }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

export const getPlacementPictures = ({ locationSale }) => {
	const method = 'GET';
	const { url, headers, body } = getData({ locationSale }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

export const deletePlacementPicture = ({ locationSale, picture })  => {
	const method = 'DELETE';
	const { url, headers, body } = getData({ locationSale, picture }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

/* Utility functions */

const getData = ({ locationSale, pictures, picture }, method) => {
	const url = getUrl({ locationSale, picture });
	const headers = getHeaders();
	let body;
	if (pictures) {
		body = new FormData();
		delete headers['Content-Type'];
		pictures.forEach(picture => body.append('pictures', picture));
	}
	return {
		url: url,
		method: method,
		headers: headers,
		body: body
	};
};


const urlMethod = 'location-sales';

const getUrl = ({ locationSale, picture }) => { 
	const baseUrl = getRequestUrl(urlMethod);
	const { CITIBOX_API_HOST } = window.process.env;
	const MOCKS = (CITIBOX_API_HOST === 'citibox.mock');
	if (MOCKS) {
		const mockUrl = getMockedPlacementPictureUrl({ picture, baseUrl, locationSale });
		return mockUrl;
	} 
	return getPlacementPictureUrl({ picture, baseUrl, locationSale });
};

const getPlacementPictureUrl = ({ picture, baseUrl, locationSale }) => {
	if (picture) {
		return `${ baseUrl }/${ locationSale.id }/placement-pictures/${ picture.id }/`;
	}
	return `${ baseUrl }/${ locationSale.id }/placement-pictures/`;
};

const getMockedPlacementPictureUrl = ({ picture, baseUrl, locationSale }) => {
	if (picture) {
		return `${ baseUrl.replace('.json', '') }-${ locationSale.id }-placement-pictures-${ picture.id }.json`;
	}
	return `${ baseUrl.replace('.json', '') }-${ locationSale.id }-placement-pictures.json`;
};

const getHeaders = () => {
	return getRequestHeaders();
};

const checkErrors = (response) => {
	if (response && response.code) {
		switch(response.code) {
		default:
			throw new ServiceError(response);
		}
	}
	return response;
};