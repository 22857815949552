import { paperInputStyles, paperTextareaStyles } from './lava-theme/paper-elements';

export const SharedStyles = `
	:host {
		display: block;
		box-sizing: border-box;
		--paper-font-caption_-_font-size: 14px;
		--paper-input-error: {
			margin-top: 3px;
			margin-left: 0.9em;
		}
	}

	:host([hidden]), [hidden] {
		display: none !important;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: var(--primary-color);
	}

	h1 {
		@apply --font-display2
	}
	h2 {
		@apply --font-display1
	}
	h3 {
		@apply --font-display1
	}
	h4 {
		@apply --font-display1
	}
	h5 {
		@apply --font-headline
	}
	h6 {
		@apply --font-title
	}

	p {
		margin: 0 0 1em 0;
		padding: 0;
	}

	a {
		color: var(--secondary-text-color);
	}

	strong {
		color: var(--primary-color);
	}

	paper-dropdown-menu {
		--paper-listbox-background-color: var(--citibox-lighty);
		--paper-listbox-color: var(--light-theme-text-color);
	}

	paper-autocomplete-suggestions {
		--suggestions-wrapper: {
			box-sizing: border-box;
			width: calc(100% - 16px);
			background-color: var(--citibox-lighty);
			margin: -16px 8px 0;
		}

		--suggestions-item: {
			border-bottom: 1px dashed var(--light-theme-divider-color);
			padding: 16px;
		}
	}

	paper-input,
	paper-dropdown-menu,
	paper-textarea {
		text-align: left;
		padding: 0em;
		margin:0;
		font-size: 18px;
		line-height: 22px;
		outline: none;
	}

	${paperInputStyles}
	${paperTextareaStyles}

	paper-checkbox::shadow #checkboxContainer {
		height: 18px;
		width: 18px;
		overflow: hidden;
		border-radius: 4px;
	}

	.hint {
		@apply --paper-input-error;
	}

	.error {
		color: var(--citibox-octopus);
	}

	@media (min-width: 769px) {
		h1 {
			@apply --font-display4
		}
		h2 {
			@apply --font-display3
		}
		h3{
			@apply --font-display2
		}
		h4{
			@apply --font-display1
		}
	}

	a.chip {
		color: currentColor;
		cursor: not-allowed;
		opacity: 0.5;
		text-decoration: none;
	}

	.chip, a.chip:link, a.chip:visited {
		box-sizing: border-box;
		display: inline-block;
		cursor: pointer;
		background-color: var(--light-theme-divider-color);
		color: var(--light-theme-secondary-color);
		font-size: 18px;
		line-height: 18px;
		padding: 6px 17px 7px 17px;
		border-radius: 18px;
		min-width: 74px;
		text-align: center;
		font-family: 'quicksand_citiboxbold';
		margin-right: 12px;
		border: none;
		text-decoration: none;
		margin-bottom: 12px;
		border: 2px none;
		opacity: 1;
		border: 2px solid transparent;
	}


	input[type="radio"]:checked + .chip {
		background-color: rgba(201, 179, 248, 0.2);
		color: var(--primary-color);
		border: 2px solid rgba(201, 179, 248, 0.2);
	}

	.chip--primary {
		background-color: var(--primary-color);
		color: var(--dark-theme-text-color);
	}
`;
