import {
	APP_ERROR,
	UPDATE_TOKEN,
	UPDATE_PAGE,
	UPDATE_OFFLINE,
	UPDATE_PAGE_COMPLETED,
	UPDATE_MEETS_REQUIREMENTS,
	OPEN_SNACKBAR,
	CLOSE_SNACKBAR,
	STEPS
} from '../actions/app.js';

const getStep = (page) => {
	return STEPS.indexOf(page) + 1;
};

const getInitialState = () => {
	return {
		steps: STEPS,
		isPageCompleted: false,
		lastStep: 1,
		meetsRequirements: true,
		errorTitle: '',
		errorMessage: '',
	};
};

const app = (state = getInitialState(), action) => {
	switch (action.type) {
	case APP_ERROR: {
		return {
			...state,
			errorTitle: action.title,
			errorMessage: action.message
		};
	}
	case UPDATE_TOKEN:
		return {
			...state,
			token: action.token
		};
	case UPDATE_PAGE:
		return {
			...state,
			page: action.page,
			isMainPage: action.isMainPage,
			step: getStep(action.page),
			lastStep: state.step
		};
	case UPDATE_OFFLINE:
		return {
			...state,
			offline: action.offline
		};
	case OPEN_SNACKBAR:
		return {
			...state,
			snackbarOpened: true,
			snackbarMessage: action.snackbarMessage
		};
	case CLOSE_SNACKBAR:
		return {
			...state,
			snackbarOpened: false,
			snackbarMessage: ''
		};
	case UPDATE_PAGE_COMPLETED:
		return {
			...state,
			isPageCompleted: action.isPageCompleted
		};
	case UPDATE_MEETS_REQUIREMENTS:
		return  {
			...state,
			meetsRequirements: !!action.meetsRequirements
		};
	default:
		return state;
	}
};

export default app;
