import { html, LitElement } from '@polymer/lit-element';
import { css } from 'lit-css';
import * as Gestures from '@polymer/polymer/lib/utils/gestures';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import { track } from '../../services/tracking.js';
import styles from './deployers-access-panel-styles';

export class DeployersAccessPanel extends LitElement {
	static get properties() {
		return {
			active: {
				type: Boolean
			},
			expanded: {
				type: Boolean,
				reflect: true
			},
			editable: {
				type: Boolean
			},
			entrances: {
				type: Array
			},
			_mainEntrances: {
				type: Array
			},
			_otherEntrances: {
				type: Array
			},
			_mainEntranceId: {
				type: String
			}
		};
	}

	static get style() {
		return css`${styles}`;
	}

	shouldUpdate() {
		return this.active;
	}

	constructor() {
		super();
		this.editable = false;
		this.active = false;
		this.expanded = false;
		this._mainEntrances = [];
		this._otherEntrances = [];
		this._handleTouchStart = this._handleTouchStart.bind(this);
		this._handleTrack = this._handleTrack.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener('touchstart', this._handleTouchStart);
		Gestures.addListener(this, 'track', this._handleTrack);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('touchstart', this._handleTouchStart);
		Gestures.removeListener(this, 'track', this._handleTrack);
	}

	updated(propChanged) {
		if(propChanged.has('entrances')) {
			this._mainEntrances = this.entrances.filter((item) => item.main_entrance === true);
			this._otherEntrances = this.entrances.filter((item) => item.main_entrance === false);
			if (this._mainEntrances.length) {
				this._mainEntranceId = this._mainEntrances[0].id;
			}
		}
	}

	render() {
		return html`
		<style>${this.constructor.style}</style>
		<a class="tap-zone" @click="${this._toggleAccessPanel}">
			<span class="bar"></span>
		</a>
		${(this._mainEntrances.length || this._otherEntrances.length) ?
		html`<header class="header">
			<h4 class="title">Accesos</h4>
			${this.entrances.length > 1 ? html`<button class="button button--textual button--edit" @click="${this._toggleEditMode}">${this.editable ? 'Guardar' : 'Editar'}</button>` : ''}
		</header>` :
		''}
		<div class="list">
			${this._mainEntrances.length ? html`
				<label class="type">Principal<hr class="separator"/></label>
				${this._mainEntrances.map((item) => this._accessAddressItem(item))}
			`: ''}

			${this._otherEntrances.length ? html`
				<label class="type">Otros<hr class="separator"/></label>
				${this._otherEntrances.map((item) => this._accessAddressItem(item))}
			`: ''}
		</div>
		`;
	}

	_accessAddressItem(item) {
		return html`
		<div class="item">
			<paper-radio-group>
				<paper-radio-button
					noink
					name="mainEntrance"
					.value="${item.id}"
					?disabled="${!this.editable}"
					@change="${this._mainEntranceChanged}"
					?checked="${this._mainEntranceId === item.id}">
					<address class="address">
						${item.road_name}, ${item.number} ${item.additional_information}
						<span class="region">${item.locality}, ${item.province} – ${item.postal_code}</span>
					</address>
				</paper-radio-button>
			</paper-radio-group>
			<!--
			<a href="#">
				<svg class="access__delete" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M22 8L19 11L22 14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					<path d="M16 14L19 11L16 8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</a>
			-->
		</div>
		`;
	}

	_toggleAccessPanel() {
		this.expanded = !this.expanded;
	}


	_handleTouchStart(e) {
		if (e.path.indexOf(this) === -1) {
			this.expanded = false;
		}
	}

	_handleTrack(e) {
		if (e.detail.ddy > 30) {
			this.expanded = false;
		}
		if (e.detail.ddy < -30) {
			this.expanded = true;
		}
	}

	_mainEntranceChanged(e) {
		this._mainEntranceId = e.target.value;
	}

	_toggleEditMode() {
		if (this.editable) {
			this.entrances = this.entrances.map((item) => {
				item.main_entrance = this._mainEntranceId === item.id;
				return item;
			});
			const event = new CustomEvent('entrances-changed', {
				detail: {
					entrances: this.entrances
				}
			});
			this.dispatchEvent(event);
		} else {
			track({ category: 'click', action: 'Edit entrances'});
		}
		this.editable = !this.editable;
	}


}

window.customElements.define('deployers-access-panel', DeployersAccessPanel);
