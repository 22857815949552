export const ButtonStyles = `

	.button {
		display: inline-block;
		height: 54px;
		border-radius: 75.32px;
		padding: 0 20px;
		font-size: 18.08px;
		box-sizing: border-box;
		border: none;
		font-family: 'quicksand_citiboxbold', 'Noto', sans-serif;
		vertical-align: middle;
		line-height: 0;
		outline: none;
		cursor: pointer;
		margin-bottom: 10px;
	}

	.button:disabled {
		opacity: var(--light-disabled-opacity);
	}

	.button--primary {
		color: var(--citibox-pure-white);
		background-color: var(--primary-color);
	}

	.button--secondary {
		background-color: transparent;
		border: 2px solid var(--citibox-grey-second);
		color: var(--citibox-grey-second);
	}

	.button--multiline {
		line-height: 21px;
	}

	.button--small {
		font-size: 14px;
		height: 28px;
	}
`;
