import { css } from 'lit-css';
import { ButtonStyles } from '../../styles/button-styles.js';

export default css`
${ButtonStyles}
:host {
	z-index: 1;
	background-color: var(--citibox-lighty);
	display: block;
	border-radius: 8px 8px 0 0;
	position: absolute;
	padding: 0 21px;
	bottom: 0;
	left: 0;
	margin: 0 24px;
	transition: transform 0.4s ease-in-out;
	transform: translateY(0);
	opacity: var(--access-panel-opacity);
	width: calc(100% - 48px - 42px);
    transform: translateY(calc(100% - 60px));
}

:host([expanded]) {
	transform: translateY(0);
}

.tap-zone {
	width: 100%;
	height: 36px;
	display: inline-block;
	margin-bottom: 0;
}

.bar {
	display: block;
	width: 22px;
	height: 3px;
	border-radius: 1.5px;
	background-color: var(--primary-text-color);
	margin-left: auto;
	margin-right: auto;
	margin-top: 12px;
}

.title {
    @apply --font-common-title;
	color: var(--primary-text-color);
	font-size: 28px;
	line-height: 33px;
	display: inline-block;
	margin-top: -11px;
}

.button--textual {
    @apply --font-common-base;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    height: auto;
    display: inline;
    background-color: transparent;
    border-radius: 0;
    width: auto;
    padding: 0;
    border: none;
}

.button--edit {
    float: right;
    display: none;
    color: var(--primary-color);
}

:host([expanded]) .button--edit {
    display: block;
}

.list {
	max-height: calc(100vh - 242px);
	position: relative;
	z-index: 1;
	overflow-x: scroll;
    background: var(--citibox-lighty) no-repeat;
	background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
	background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
	background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
	background-position: 0 0, 0 100%;
	background-size: 100% 14px;
}

.list:before,
.list:after {
	content: "";
	position: relative;
	z-index: -1;
	display: block;
	height: 30px;
	margin: 0 0 -30px;
	background: -webkit-linear-gradient(top, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
	background: -moz-linear-gradient(top, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
	background: linear-gradient(to bottom, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
}

.list:after {
	margin: -30px 0 0;
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
}

.link {
	text-decoration: none;
	border-bottom: 1px solid;
	display: inline-block;
	color: var(--primary-text-color);
	font-weight: bold;
	font-family: Lato;
	font-size: 18px;
	line-height: 22px;
	float: right;
	margin-top: -5px;
}


.type {
    color: var(--primary-text-color);
    opacity: 0.5;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	font-size: 14px;
	font-family: Lato;
	display: inline-block;
}

.separator {
	top: 3px;
	margin-left: 10px;
	border: none;
	display: inline-block;
	width: 100%;
	position: relative;
	height: 1px;
	background-color: var(--primary-text-color);
}

.item {
	display: inline-block;
	width: 100%;
	position: relative;
}

.address {
    display: inline-block;
    box-sizing: border-box;
	width: 100%;
	@apply --font-body2;
	font-style: normal;
	font-weight: bold;
	margin: 0;
	color: var(--primary-text-color);
	padding: 12px;
}
paper-radio-group {
    display: block;
}
paper-radio-button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    --paper-radio-button-radio-container: {
        opacity: 1;
        flex: 0 0 auto;
        transition: 0.3s opacity;
    }
    --paper-radio-button-label: {
        margin-left: 0;
        flex: 1 100%;
        margin-right: var(--paper-radio-button-label, 10px);
    }
}
paper-radio-button[disabled] {
    --paper-radio-button-radio-container: {
        opacity: 0;
        flex: 0 0 auto;
        transition: 0.3sº opacity;
    }

    --paper-radio-button-label: {
        flex: 1 100%;
        opacity: 1 !important;
        margin-left: 0;
        margin-right: var(--paper-radio-button-label, 10px);
    }
}
paper-radio-button .address {
    padding: 0;
}

.region {
	display: inline-block;
	width: 100%;
	@apply --font-body1;
    color: var(--primary-text-color);
    opacity: 0.8;
	margin-left: 0px;
}

.delete {
	stroke: var(--light-theme-background-color);
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -10px;
}

@media (min-width: 890px) {

	:host {
		width: 37.5%;
		height: 453px;
		box-sizing: border-box;
		padding: 60px 40px 0 40px;
		position: relative;
		margin: 0;
		padding: 60px 40px 0 40px;
		bottom: auto;
		left: auto;
		transform: translateY(0);
		border-radius: 0;
	}

	.bar {
		display: none;
	}

	.tap-zone {
		display: none;
	}

	.title {
		font-size: 28px;
		line-height: 32px;
    }

    .button--edit {
        display: block;
    }

	.list {
		max-height: calc(100% - 60px);
	}
}
`;
