import { html, LitElement } from '@polymer/lit-element';
import { css } from 'lit-css';
import styles from './deployers-info-panel-styles';

const resolutions = [
	{ density: '1x', sufix: '' },
	{ density: '2x', sufix: '@2x' },
	{ density: '3x', sufix: '@3x' }
];

const DARK_COLOR = '#aba4ba32';
const LIGHT_COLOR = '#ffffff32';

export class DeployersInfoPanel extends LitElement {
	static get properties() {
		return {
			active: {
				type: Boolean
			},
			open: {
				type: Boolean,
				reflect: true
			},
			data: {
				type: Object
			},
			bubbleColor: {
				type: String
			}
		};
	}

	static get style() {
		return css`${styles}`;
	}

	shouldUpdate() {
		return this.active;
	}

	constructor() {
		super();
		this.open = false;
		this.data = {};
		const tabletMediaQuery = window.matchMedia('(min-width: 1366px)');
		tabletMediaQuery.addListener(this.updateBubbleColor);
		this.updateBubbleColor(tabletMediaQuery);
	}

	updateBubbleColor(tabletMediaQuery) {
		if (tabletMediaQuery.matches) {
			this.bubbleColor = LIGHT_COLOR;
		} else {
			this.bubbleColor = DARK_COLOR;
		}
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	updated(propChanged) {
		super.updated(propChanged);
		if (propChanged.has('open')) {
			this.dispatchEvent(new CustomEvent('open-changed', {
				detail: {
					value: this.open
				}
			}));
		}
	}

	render() {
		return html`
			<style>${this.constructor.style}</style>
			<div class="content">
				<div class="graphics">
					<div class="bubble">
						<img .src="${this.data.image}" class="image" .srcset="${this._srcset(this.data.image)}"/>
					</div>
				</div>
				<a class="link" @click="${this.toggleInfoPanel}">← Volver</a>
				<h3 class="title title--l">${this.data.title}</h3>
				<p class="description">
					${this.data.description}
				</p>
			</div>
		`;
	}

	_srcset(imageUrl) {
		return imageUrl ?
			resolutions.map((resolution) => {
				const file = imageUrl.replace('.png', `${resolution.sufix}.png`);
				return `${file} ${resolution.density}`;
			}).join(', ') :
			'';
	}

	toggleInfoPanel() {
		this.open = !this.open;
	}
}

window.customElements.define('deployers-info-panel', DeployersInfoPanel);
