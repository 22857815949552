import { html, LitElement } from '@polymer/lit-element';
import { css } from 'lit-css';
import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { installMediaQueryWatcher } from 'pwa-helpers/media-query.js';
import { installOfflineWatcher } from 'pwa-helpers/network.js';
import { installRouter } from 'pwa-helpers/router.js';

import { updateMetadata } from './mixins/metadata.js';
import { store } from './store.js';
import prospection from './reducers/prospection.js';

store.addReducers({
	prospection
});
import { init, navigate, updateOffline, updateLayout, goBack, startSteps, prevStep, nextStep, goToHome, goToDismiss } from './actions/app.js';
import { dismissProspection, saveEntrances } from './actions/prospection.js';
import './components/snack-bar.js';
import { track } from './services/tracking.js';

/* Custom elements */
import '@polymer/paper-checkbox/paper-checkbox.js';
import './components/citibox-page-indicator.js';
import './components/deployers-access-panel/deployers-access-panel.js';
import './components/deployers-info-panel/deployers-info-panel.js';

/* Styles */
import styles from './citibox-app-styles';

const infoItems = [
	{
		image: '/images/steps/step1.png',
		title: 'Primeros pasos',
		description: 'Para dar de alta una ubicación o editar una existente introduce la dirección en el buscador.',
	},
	{
		image: '/images/steps/step2.png',
		title: 'Lo básico para empezar',
		description: '¿Vivienda u oficina? Nos acaban de presentar y para conocernos empezamos por lo esencial.',
	},
	{
		image: '/images/steps/step3.png',
		title: 'Un poco de contexto',
		description: 'Eres nuestros ojos. Con esta información sobre el espacio definiremos las características de los buzones.',
	},
	{
		image: '/images/steps/step4.png',
		title: 'Ábrete sésamo',
		description: 'Ya queda poco para acabar...En este paso estamos recogiendo datos sobre el sistema de apertura de puertas de esta ubicación.',
	},
	{
		image: '/images/steps/step5.png',
		title: '¡Seguimos en contacto!',
		description: '¿Quién representa a esta comunidad? Puede ser el presidente de la comunidad o el administrador de la finca, cualquiera de las dos opciones nos vale.',
	},
	{
		image: '/images/steps/step6.png',
		title: 'Primer punto del día…',
		description: 'En la junta de la comunidad los vecinos hablarán de nuestros buzones Citibox, por eso es muy importante para nosotros marcar esa fecha en el calendario.',
	},
	{
		image: '/images/steps/step5.png',
		title: '¡Seguimos en contacto!',
		description: '¿Quién representa a esta comunidad? Puede ser el presidente de la comunidad o el administrador de la finca, cualquiera de las dos opciones nos vale.',
	},
	{
		image: '/images/steps/step7.png',
		title: 'Una firma y acabamos',
		description: 'Para finalizar el proceso de alta es imprescindible que adjuntes el contrato firmado en formato PDF.',
	},
];

class CitiboxApp extends connect(store)(LitElement) {

	static get style() {
		return css`${styles}`;
	}

	static get properties() {
		return {
			appTitle: {
				type: String
			},
			isMainPage: {
				attribute: 'main-page',
				reflect: true,
				type: Boolean
			},
			page: {
				type: String,
				reflect: true
			},
			_snackbarOpened: {
				type: Boolean
			},
			_snackbarMessage: {
				type: String
			},
			_offline: {
				type: Boolean
			},
			_infoPanelOpen: {
				type: Boolean
			},
			_mobileKeyboardOpened: {
				type: Boolean
			},
			_info: {
				type: Object
			},
			_screenHeight: {
				type: Number
			},
			_screenWidth: {
				type: Number
			},
			_isPageCompleted: {
				type: Boolean
			},
			_entrances: {
				type: Array
			}
		};
	}

	render() {
		return html`
			<style>
				${this.constructor.style}
			</style>
			${ this._header}
			<div class="wrapper">
				<main class="content ${this._infoPanelOpen ? 'scrim' : ''}">
					<section class="page">
						<cbp-building @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'building'}"></cbp-building>
						<crm-prospection-features @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'features'}"></crm-prospection-features>
						<crm-prospection-space @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'space'}"></crm-prospection-space>
						<crm-prospection-connections @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'connections'}"></crm-prospection-connections>
						<location-contacts @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'contacts'}"></location-contacts>
						<crm-prospection-board @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'board'}"></crm-prospection-board>
						<crm-prospection-agent @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'agent'}"></crm-prospection-agent>
						<crm-prospection-signature @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'signature'}"></crm-prospection-signature>
						<crm-prospection-success @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'success'}"></crm-prospection-success>
						<crm-prospection-error @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'error'}"></crm-prospection-error>
						<crm-prospection-dismiss @page-completed="${this.handlePageCompleted}" ?active="${this.page === 'dismiss'}"></crm-prospection-dismiss>
					</section>
					${ this._accessPanel}
					${ this._buttonPanel}
					${ this._pageIndicator}

				</main>
					${ this._infoPanel}
			</div>

			<snack-bar
				?active="${this._snackbarOpened}">
				${this._snackbarMessage}
			</snack-bar>
		`;
	}

	get _header() {
		const { page } = this;
		return html`<header class="menu ${this._infoPanelOpen ? 'scrim' : ''}">
			<div class="menu__wrapper">
				<a href="/" @click="${this.restartProcess}">
					<svg class="logo" width="95" height="24" viewBox="0 0 95 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M45.4852 23.4341L45.4899 23.4395L45.4952 23.4444C45.8071 23.7398 46.2158 23.9024 46.6544 23.9056C47.1239 23.9056 47.519 23.7413 47.8212 23.4053C48.1386 23.0749 48.2991 22.6687 48.2991 22.1982V21.7955C48.8296 22.3608 49.4825 22.8518 50.2414 23.2548C51.1721 23.7468 52.1962 23.9988 53.2699 24C54.6314 23.9981 55.906 23.6156 57.0424 22.868C58.1761 22.1172 59.0801 21.0494 59.7196 19.7056C60.376 18.3615 60.7097 16.8373 60.711 15.1791C60.7097 13.5207 60.3757 12.0064 59.7191 10.6829C59.0796 9.36019 58.1759 8.30359 57.0424 7.55308C55.9057 6.80531 54.6109 6.42299 53.2093 6.42107C52.133 6.42272 51.0955 6.70825 50.1433 7.26466C49.3936 7.7051 48.7732 8.22367 48.2991 8.8053V1.70732C48.2991 1.23687 48.1386 0.830701 47.8212 0.500203C47.519 0.164221 47.1239 0 46.6544 0C46.1848 0 45.7898 0.164221 45.4876 0.500203C45.1856 0.8148 45.04 1.22357 45.04 1.70732V22.1982C45.04 22.6836 45.1864 23.1003 45.4852 23.4341ZM57.7249 15.1791C57.726 16.2487 57.5232 17.2018 57.1069 18.0826C56.7139 18.9427 56.1704 19.6059 55.4425 20.1141C54.7187 20.6032 53.931 20.8391 53.0273 20.8372C52.1028 20.8389 51.3051 20.603 50.5814 20.1141C49.8537 19.6056 49.2994 18.9414 48.8867 18.0826C48.4912 17.2233 48.2981 16.2701 48.2991 15.1791C48.2981 14.0881 48.4909 13.1458 48.8862 12.3078C49.2989 11.4489 49.8521 10.7956 50.5798 10.3082C51.3041 9.81845 52.1023 9.58212 53.0273 9.58404C53.9317 9.58212 54.7198 9.81831 55.4443 10.3082C56.1715 10.7951 56.7139 11.447 57.1061 12.3053L57.1077 12.3088C57.5235 13.168 57.7263 14.1096 57.7249 15.1791Z" fill="white"/>
						<path d="M13.5414 10.4911L13.5432 10.4885L13.5453 10.4859C13.7853 10.1681 13.917 9.78594 13.9197 9.39869C13.9197 8.47738 13.3042 7.7595 12.1991 7.22393C11.1188 6.69233 9.74688 6.4227 8.12126 6.42188C6.55074 6.42338 5.13026 6.81269 3.90626 7.57759C2.68435 8.3244 1.71428 9.38594 1.02936 10.7252C0.347841 12.0442 0.00131162 13.5426 0 15.1756C0.00104929 16.8076 0.326068 18.3139 0.965874 19.6529L0.967448 19.6561C1.63165 20.9746 2.58074 22.036 3.78191 22.8038L3.78375 22.8051C4.98781 23.5493 6.39806 23.928 7.96859 23.9294C9.0192 23.929 10.0113 23.7896 10.9203 23.5142C11.8494 23.2378 12.602 22.878 13.1474 22.4482C13.7137 21.9957 14.0419 21.509 14.0419 20.9841C14.0419 20.5282 13.9081 20.1452 13.6282 19.8546C13.3651 19.5386 12.9984 19.3888 12.5532 19.3888C12.3114 19.3897 12.0787 19.4404 11.8953 19.533L11.8911 19.5352L11.8869 19.5376C11.7377 19.6255 11.554 19.7585 11.3471 19.9283C11.1091 20.1116 10.9265 20.2402 10.7909 20.3201C10.1716 20.637 9.34658 20.8015 8.30463 20.8015C7.30833 20.8031 6.44738 20.5649 5.67484 20.0744C4.9002 19.5866 4.31102 18.9319 3.87504 18.074C3.45611 17.2162 3.25176 16.2642 3.25281 15.1756C3.2515 14.1085 3.46555 13.1681 3.90468 12.3101C4.34119 11.4299 4.93955 10.7656 5.73439 10.2779C6.52739 9.78704 7.41981 9.54824 8.45756 9.54975C8.81563 9.54948 9.20781 9.58992 9.6493 9.67244C10.0845 9.75345 10.4053 9.84954 10.6354 9.96702C10.8906 10.1286 11.0661 10.2611 11.1776 10.3765L11.2017 10.3972C11.4688 10.587 11.6789 10.7273 11.8258 10.8136L11.83 10.8161L11.8342 10.8183C12.0296 10.9186 12.2728 10.9626 12.5532 10.9626C12.9498 10.9626 13.2835 10.8038 13.5414 10.4911Z" fill="white"/>
						<path d="M37.5242 23.1768L37.5283 23.1812C37.8415 23.5065 38.2259 23.6705 38.672 23.6705C39.1351 23.6705 39.5221 23.5087 39.8179 23.1791C40.1299 22.8542 40.2872 22.4559 40.2872 21.9937V8.34928C40.2872 7.88712 40.1299 7.48882 39.8179 7.16401C39.5221 6.83444 39.1351 6.67263 38.672 6.67263C38.2259 6.67263 37.8415 6.83661 37.5283 7.16185L37.5242 7.16618C37.2428 7.48525 37.0899 7.89924 37.0873 8.34928V21.9937C37.0899 22.4437 37.2427 22.8578 37.5242 23.1768Z" fill="white"/>
						<path d="M17.8554 23.1812L17.8513 23.1768C17.57 22.8578 17.4172 22.4437 17.4146 21.9937V8.34928C17.4172 7.89924 17.57 7.48525 17.8513 7.16629L17.8554 7.16185C18.1687 6.83661 18.5531 6.67263 18.9993 6.67263C19.4623 6.67263 19.8493 6.83444 20.1452 7.16401C20.4571 7.48882 20.6143 7.88712 20.6143 8.34928V21.9937C20.6143 22.4559 20.4571 22.8542 20.1452 23.1791C19.8493 23.5087 19.4623 23.6705 18.9993 23.6705C18.5531 23.6705 18.1687 23.5065 17.8554 23.1812Z" fill="white"/>
						<path d="M86.2622 15.3968L81.6646 9.38421C81.4488 9.08959 81.3306 8.74498 81.3283 8.40177C81.3283 7.93853 81.4862 7.53958 81.7996 7.21434L81.8035 7.21045C82.1338 6.8904 82.5155 6.72513 82.9433 6.72513C83.4805 6.72513 83.9205 6.96086 84.2524 7.41912L84.2536 7.42096L88.2823 12.7021L92.0728 7.45559L92.0738 7.45397C92.4028 6.97677 92.8321 6.72513 93.3548 6.72513C93.7786 6.72513 94.1423 6.87709 94.4358 7.18057C94.7282 7.46187 94.8789 7.80876 94.8789 8.21269C94.8789 8.64335 94.7584 9.00929 94.5074 9.29589L90.0959 15.0825L94.6294 21.0583C94.8666 21.3335 94.9975 21.682 95.0003 22.0463C95.0003 22.5128 94.8298 22.9214 94.4987 23.2651L94.4947 23.2691C94.1664 23.5874 93.7951 23.7545 93.3851 23.7545C92.8631 23.7545 92.4251 23.5036 92.0767 23.0294L92.0756 23.028L88.1349 17.8685L84.2541 23.0265C84.1011 23.2501 83.9007 23.4329 83.6819 23.5481C83.4628 23.6605 83.2148 23.7218 82.9737 23.723C82.6015 23.7203 82.2278 23.5755 81.9036 23.3089C81.9006 23.3064 81.8977 23.3037 81.8948 23.301C81.5837 23.0011 81.4194 22.644 81.4194 22.2353C81.4194 21.8228 81.5413 21.4681 81.7912 21.1832L86.2622 15.3968Z" fill="white"/>
						<path d="M38.6503 5.01569C39.6488 5.01569 40.4583 4.17536 40.4583 3.13881C40.4583 2.10204 39.6488 1.26172 38.6503 1.26172C37.6517 1.26172 36.8423 2.10204 36.8423 3.13881C36.8423 4.17536 37.6517 5.01569 38.6503 5.01569Z" fill="white"/>
						<path d="M20.8749 3.13881C20.8749 4.17536 20.0653 5.01569 19.0668 5.01569C18.0683 5.01569 17.2588 4.17536 17.2588 3.13881C17.2588 2.10204 18.0683 1.26172 19.0668 1.26172C20.0653 1.26172 20.8749 2.10204 20.8749 3.13881Z" fill="white"/>
						<path d="M29.7022 19.3165C29.7173 17.1417 29.7123 14.9667 29.7072 12.7917C29.7051 11.8908 29.703 10.9898 29.7024 10.0889H32.0635C32.9195 10.0889 33.5585 9.46397 33.5571 8.56953C33.5558 7.67596 32.8574 7.0192 32.0635 7.0192H29.7022V4.53632C29.7022 3.62619 29.028 2.84355 28.1148 2.85968C27.6725 2.86747 27.2713 3.02484 26.9781 3.31664C26.6838 3.60952 26.5088 4.02752 26.5024 4.53416C26.501 4.64834 26.5011 4.76242 26.5013 4.87661L26.5013 4.97986C26.5022 5.25662 26.5029 5.49873 26.4839 5.71509C26.4567 6.03687 26.3964 6.27498 26.2263 6.49892C26.0491 6.73757 25.9209 6.84624 25.7508 6.90945C25.5671 6.97774 25.323 6.99852 24.8925 7.01941C24.4429 7.04117 24.0774 7.17614 23.8186 7.44012C23.5595 7.70442 23.4284 8.07696 23.4065 8.53003C23.3837 8.99976 23.5382 9.39373 23.8153 9.66918C24.0907 9.94291 24.4761 10.0889 24.9 10.0889H26.5022C26.5011 11.0002 26.4975 11.9116 26.4939 12.8231V12.826C26.4853 15.0319 26.4766 17.2382 26.5024 19.4433C26.5141 20.4474 26.5609 21.2927 27.1652 22.272C27.7953 23.2826 29.0304 23.6243 30.1709 23.6703C30.7466 23.693 31.1684 23.6423 31.5001 23.551C31.8367 23.4568 32.0962 23.3195 32.3504 23.1783C32.622 23.0262 32.9111 22.7353 33.0582 22.3816C33.2085 22.0116 33.218 21.5613 32.9682 21.0828C32.8435 20.844 32.6946 20.6854 32.5182 20.595C32.3507 20.5126 32.1554 20.4933 31.9787 20.5101C31.7994 20.5284 31.625 20.5728 31.4509 20.6172L31.4481 20.6179C31.2657 20.6644 31.0836 20.7108 30.8963 20.7274C30.5504 20.7586 30.2559 20.6373 30.0463 20.4013C29.8349 20.1631 29.6989 19.7949 29.7022 19.3165Z" fill="white"/>
						<path d="M63.7673 18.7782C64.3551 20.2284 65.5285 20.3703 66.1811 19.7633C66.8337 19.1562 67.0254 18.4678 66.5247 17.3373C66.0241 16.2067 65.8455 14.1431 66.7421 12.5531C67.2092 11.666 67.9917 10.7714 68.9969 10.232C70.0021 9.69256 71.7252 9.27727 73.4772 10.0273C74.7541 10.5738 75.4414 10.5103 76.1 9.78575C76.7586 9.06124 76.3195 7.8054 75.0145 7.22949C73.7094 6.65358 72.8582 6.40725 71.4301 6.40595C69.9275 6.40725 68.4998 6.77708 67.2419 7.56404C65.984 8.35101 64.9287 9.42284 64.2123 10.6748C63.4959 11.9266 62.9899 13.7855 63.0363 15.222C63.0826 16.6586 63.1795 17.3279 63.7673 18.7782Z" fill="white"/>
						<path d="M79.113 11.6028C78.5252 10.1526 77.3518 10.0107 76.6992 10.6177C76.0466 11.2248 75.855 11.9132 76.3556 13.0437C76.8562 14.1743 77.0348 16.2379 76.1383 17.8279C75.6711 18.715 74.8886 19.6096 73.8834 20.149C72.8783 20.6884 71.1552 21.1037 69.4031 20.3537C68.1263 19.8072 67.4389 19.8707 66.7803 20.5952C66.1217 21.3198 66.5608 22.5756 67.8658 23.1515C69.1709 23.7274 70.0222 23.9737 71.4503 23.975C72.9528 23.9737 74.3805 23.6039 75.6384 22.8169C76.8963 22.03 77.9517 20.9581 78.668 19.7062C79.3844 18.4544 79.8904 16.5955 79.844 15.159C79.7977 13.7224 79.7009 13.0531 79.113 11.6028Z" fill="white"/>
					</svg>
				</a>
				<div class="spacer"></div>
				<a href="/logout" class="link">Salir</a>
				${(page === 'error' || page === 'success' || page === 'dismiss') ? '' : html`
				<a href="javascript:void(0);" @click="${this._toggleInfoPanel}" class="info-button">
					<svg class="info-button__background" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M42 25.5C42 36.5457 29.5457 41 18.5 41C7.4543 41 0 29.5457 0 18.5C0 7.4543 4.4543 0 15.5 0C26.5457 0 42 14.4543 42 25.5Z" fill="white"/>
					</svg>
					<label class="info-button__text">i</label>
				</a>
				`}
			</div>
		</header>`;
	}

	get _accessPanel() {
		return html`<deployers-access-panel
			class="access"
			?active="${!this._mobileKeyboardOpened}"
			.entrances="${this._entrances}"
			@entrances-changed="${this._entrancesChanged}"
		></deployers-access-panel>`;
	}

	get _buttonPanel() {
		const {
			_step,
			page,
			_isPageCompleted,
			nextButtonText
		} = this;
		return html`<nav class="buttons">
			${ _step > 1 ?
				html`<p class="dismiss-location">
				<a class="link" @click="${this.goToDismiss}">Desestimar localización</a>
			</p>`:
				''}
			${ _step > 1 ? html`<button class="button button--secondary button--go-back" @click="${this.prevStep}">Atrás</button>` : ''}
			${ page === 'error' || page === 'dismiss' ? html`<button class="button button--secondary button--go-back" @click="${this.undo}">Volver</button>` : ''}
			<button ?disabled="${ !_isPageCompleted}"
				class="button button--primary ${ (page === 'error') ? 'button--multiline' : ''}"
				@click="${this.nextStep}">
				${ nextButtonText}
			</button>
		</nav>`;
	}

	get nextButtonText() {
		const { _step, _steps, page } = this;
		switch (page) {
			case 'success':
				return 'Nuevo contrato';
			case 'error':
				return html`Nuevo<br/> contrato`;
			case 'dismiss':
				return 'Desestimar';
		}
		switch (_step) {
			case 1:
				return 'Confirmar';
			case _steps.length:
				return 'Finalizar';
			default:
				return 'Siguiente';
		}
	}

	goToDismiss() {
		track({ category: 'click', action: 'Dismiss location' });
		store.dispatch(goToDismiss());
	}

	get _pageIndicator() {
		return this._showPageIndicator() ?
			html`<citibox-page-indicator
				class="page-indicator"
				pages="${this._steps.length}"
				current="${this._step}"
			></citibox-page-indicator>` :
			'';
	}

	get _infoPanel() {
		return html`<deployers-info-panel
			class="info"
			?active="${this.page !== 'error' && this.page !== 'success' && this.page !== 'dismiss'}"
			?open="${this._infoPanelOpen}"
			@open-changed="${this._openChanged}"
			.data="${this._info}"
		></deployers-info-panel>`;
	}

	constructor() {
		super();
		store.dispatch(init());
		setPassiveTouchGestures(true);
		this.handleResize = this.handleResize.bind(this);
		this._mobileKeyboardOpened = false;
		this._isPageCompleted = false;
	}

	_openChanged(e) {
		this._infoPanelOpen = e.detail.value;
	}

	_toggleInfoPanel(e) {
		e.preventDefault();
		this._infoPanelOpen = !this._infoPanelOpen;
	}

	_showPageIndicator() {
		return this._step > 0 && this.page !== 'error' && this.page !== 'success';
	}

	_entrancesChanged(e) {
		store.dispatch(saveEntrances(e.detail.entrances));
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener('resize', this.handleResize);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('resize', this.handleResize);
	}

	firstUpdated() {
		installRouter(location =>
			store.dispatch(navigate(window.decodeURIComponent(location.pathname)))
		);
		installOfflineWatcher(offline => store.dispatch(updateOffline(offline)));
		installMediaQueryWatcher('(min-width: 769px)', () => {
			const pageIndicator = this.shadowRoot.querySelector('.page-indicator');
			pageIndicator && pageIndicator.requestUpdate();
		});
		installMediaQueryWatcher('(min-width: 460px)', matches =>
			store.dispatch(updateLayout(matches))
		);
		this._screenHeight = window.innerHeight;
		this._screenWidth = window.innerWidth;
	}

	handleResize(e) {
		if (this._screenWidth < 769 && e.target.innerHeight < (this._screenHeight * 0.8)) {
			this._mobileKeyboardOpened = true;
			this.style.setProperty('--page-height', 'calc(100vh - 87px)');
		}
		if (this._screenWidth < 769 && e.target.innerHeight > this._screenHeight) {
			this._mobileKeyboardOpened = false;
			this.style.setProperty('--page-height', 'calc(100vh - 268px)');
		}
		this._screenHeight = window.innerHeight;
		this._screenWidth = window.innerWidth;
	}

	updated(changedProps) {
		if (changedProps.has('page')) {
			const pageTitle = this.appTitle; // TODO: Assign readable titles to every section
			updateMetadata({
				title: pageTitle,
				description: pageTitle,
				color: getComputedStyle(this).getPropertyValue('--app-background-color').trim()
			});
		}
	}

	restartProcess(e) {
		track({ category: 'click', action: 'Restart process' });
		e.preventDefault();
		store.dispatch(goToHome());
	}

	prevStep(e) {
		track({ category: 'click', action: 'Previous step' });
		e.preventDefault();
		if (this._step === 2) {
			this.restartProcess(e);
		} else {
			store.dispatch(prevStep());
		}
	}

	undo(/* event */) {
		store.dispatch(goBack());
	}

	nextStep(e) {
		track({ category: 'click', action: 'Next step' });
		e.preventDefault();
		if (this.page === 'success' || this.page === 'error') {
			store.dispatch(goToHome());
		} else if (this.page === 'dismiss') {
			store.dispatch(dismissProspection());
			store.dispatch(goToHome());
		} else {
			if (this._step === 1) {
				store.dispatch(startSteps());
			} else {
				store.dispatch(nextStep());
			}
		}
	}

	stateChanged(state) {
		this.pageTitle = 'Citibox';
		this._token = state.app.token;
		this.page = state.app.page;
		this.isMainPage = state.app.isMainPage;
		this._step = state.app.step;
		this._steps = state.app.steps;
		this._offline = state.app.offline;
		this._snackbarOpened = state.app.snackbarOpened;
		this._snackbarMessage = state.app.snackbarMessage;
		this._info = this._step > 0 ? infoItems[this._step - 1] : {};
		this._isPageCompleted = state.app.isPageCompleted;
		if (state.prospection.current && state.prospection.current.entrances) {
			this._entrances = state.prospection.current.entrances.map(a => ({ ...a }));
		}
	}
}

window.customElements.define('citibox-app', CitiboxApp);
