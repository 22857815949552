export {};  // ensure this file can only be parsed as a module.

const style = document.createElement('style');
style.rel = 'stylesheet';
style.type = 'text/css';
style.innerHTML = `
@font-face {
	font-family: 'quicksand_citiboxbold';
	font-display: fallback;
    src: url('./fonts/quicksandcitibox-bold-webfont.woff2') format('woff2'),
         url('./fonts/quicksandcitibox-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}`;
document.head.appendChild(style);



