import { LitElement, html } from '@polymer/lit-element';
import { SharedStyles } from '../styles/shared-styles.js';

class CitiboxPageIndicator extends LitElement {
	static get properties() {
		return {
			pages: {
				type: Number
			},
			current: {
				type: Number
			}
		};
	}
	
	constructor() {
		super();
	}
	
	get itemTemplates() {
		const completeTemplate = [];
		let isTheLastItem = false;
		for (let i = 1; i < this.pages + 1; i++) {
			isTheLastItem = (i === this.pages);
			if (i === this.current) {
				if (isTheLastItem) {
					completeTemplate.push(html`<div class="bar last">${i}</div>`);
				} else {
					completeTemplate.push(html`<div class="bar">${i}</div>`);
				}
			} else if (i < this.current) {
				if (isTheLastItem) {
					completeTemplate.push(html`<div class="circle circle--filled last">${i}</div>`);
				} else {
					completeTemplate.push(html`<div class="circle circle--filled">${i}</div>`);
				}
			} else {
				if (isTheLastItem) {
					completeTemplate.push(html`<div class="circle circle--empty last">${i}</div>`);
				} else {
					completeTemplate.push(html`<div class="circle circle--empty">${i}</div>`);
				}
			}
		}
		return completeTemplate;
	}
	
	updateCssVariables() {
		const width = this.getBoundingClientRect().width;
		const height = this.getBoundingClientRect().height;
		const margin = Math.floor((width - (this.pages - 1) * (height + 2) - 3 * (height + 2)) /(this.pages - 1));
		this.style.setProperty('--page-indicator-height', `${height}px`);
		this.style.setProperty('--page-indicator-margin', `${margin}px`);
		this.style.setProperty('--page-indicator-border-radius', `${height / 2}px`);
		this.style.setProperty('--page-indicator-line-height', `${height * 0.75}px`);
		this.style.setProperty('--page-indicator-font-size', `${height * 0.75}px`);
		this.style.setProperty('--page-indicator-bar-width', `${height * 3}px`);
	}
	
	update() {
		super.update();
		this.updateCssVariables();
	}
	
	render() {
		return html`
		<style>
		${SharedStyles}
		:host {
			-webkit-box-sizing: border-box; 
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			display: block;
			margin: 0;
			padding: 0;
		}
		
		.circle, .bar {
			display: inline-block;
			border-radius: var(--page-indicator-border-radius);
			-webkit-box-sizing: border-box; 
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border: 1.5px solid var(--page-indicator-primary-color);
			margin-right: var(--page-indicator-margin);
			font-family: var(--font-display2_-_font-family);
			font-weight: bold;
			font-size: var(--page-indicator-font-size);
			line-height: var(--page-indicator-line-height);
			text-align: center;
		}
		
		.circle:last-child, .bar:last-child, .last {
			margin-right: 0;
		}
		
		.bar {
			color: var(--page-indicator-secondary-color);
			background-color: var(--page-indicator-primary-color);
			height: var(--page-indicator-height);
			width:var(--page-indicator-bar-width);
		}
		.circle {
			height: var(--page-indicator-height);
			width: var(--page-indicator-height);
		}
		
		.circle--filled {
			background-color: var(--page-indicator-primary-color);
			color: var(--page-indicator-secondary-color);
		}
		
		.circle--empty {
			color: var(--page-indicator-primary-color);
		}
		</style>
		${this.itemTemplates}
		`;
	}
}

window.customElements.define('citibox-page-indicator', CitiboxPageIndicator);
