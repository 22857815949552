import { ThemeBaseStyles } from './theme-base.js';

export const CitiboxStyles = `
	${ThemeBaseStyles}
	body {
		/*
		* You can use these generic variables in your elements for easy theming.
		* For example, if all your elements use \`--primary-text-color\` as its main
		* color, then switching from a light to a dark theme is just a matter of
		* changing the value of \`--primary-text-color\` in your application.
		*/
		--primary-text-color: var(--light-theme-text-color);
		--primary-background-color: var(--light-theme-background-color);
		--secondary-text-color: var(--light-theme-secondary-color);
		--disabled-text-color: var(--light-theme-disabled-color);
		--divider-color: var(--light-theme-divider-color);
		--error-color: var(--citibox-octopus);

		/*
		* Primary and accent colors. Also see color.html for more colors.
		*/
		--primary-color: var(--citibox-party);
		--light-primary-color: var(--citibox-sunset);
		--dark-primary-color: var(--citibox-party-darks);

		--accent-color: var(--citibox-unicorn);
		--light-accent-color: var(--citibox-rocket);
		--dark-accent-color: var(--citibox-alien);

		/*
		* Material Design Light background theme
		*/
		--light-theme-background-color: var(--citibox-pure-white);
		--light-theme-base-color: var(--citibox-pure-black);
		--light-theme-text-color: var(--citibox-grey-first);
		--light-theme-secondary-color: var(--citibox-grey-second);  /* for secondary text and icons */
		--light-theme-disabled-color: var(--citibox-grey-third);  /* disabled/hint text */
		--light-theme-divider-color: var(--citibox-grey-fourth);

		/*
		* Material Design Dark background theme
		*/
		--dark-theme-background-color: var(--citibox-grey-first);
		--dark-theme-base-color: var(--citibox-pure-white);
		--dark-theme-text-color: var(--citibox-pure-white);
		--dark-theme-secondary-color: var(--citibox-grey-fourth);  /* for secondary text and icons */
		--dark-theme-disabled-color: var(--citibox-grey-third);  /* disabled/hint text */
		--dark-theme-divider-color: var(--citibox-grey-second);

		/*
		* Deprecated values because of their confusing names.
		*/
		--text-primary-color: var(--light-theme-text-color);
		--default-primary-color: var(--primary-color);

		/* 
		* Variables for Citibox web components
		*/
		
		/* Text field */
		--text-field-focused-color: var(--primary-color);
		--text-field-error-color: var(--error-color);
		--text-field-border-color: var(--citibox-grey-third);
		--text-field-label-color: var(--citibox-grey-third);
		--text-field-label-disabled-color: var(--citibox-grey-third);
		--text-field-label-disabled-floating-color:  var(--citibox-grey-third);
		--text-field-label-focused-color: var(--primary-color);
		--text-field-text-color: var(--primary-text-color);
		--text-field-disabled-background-color: transparent;
		--text-field-disabled-text-color: var(--citibox-grey-third);
		--text-field-focused-color: var(--primary-color);
		--text-field-hint-color: var(--primary-color);
		--text-field-hint-disabled-color:  var(--citibox-grey-third);

		/* Phone field */
		--phone-field-dropdown-background-color: var(--citibox-lighty);
		--phone-field-dropdown-text-color: var(--primary-text-color);
		--phone-field-dropdown-prefix-text-color: var(--primary-text-color);
		--phone-field-dropdown-arrow-color: var(--primary-text-color);
		--phone-field-dropdown-shadow-color: var(--citibox-grey-second);

	}
`;

const $_documentContainer = document.createElement('template');
$_documentContainer.setAttribute('style', 'display: none;');
$_documentContainer.innerHTML = `<dom-module id="citibox-theme">
  <template>
    <style>${CitiboxStyles}</style>
  </template>
</dom-module>`;

document.head.appendChild($_documentContainer.content);
