import { doRequest, getRequestUrl, getRequestHeaders } from './utils/request.js';
import { ServiceError } from './utils/errors.js';

export const uploadIntercomPicture = ({ locationSale, picture }) => {
	const method = 'PUT';
	const { url, headers, body } = getData({ locationSale, picture }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

export const deleteIntercomPicture = ({ locationSale }) => {
	const method = 'PUT';
	const { url, headers, body } = getData({ locationSale }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

/* Utility functions */

const getData = ({ locationSale, picture }, method) => {
	const url = getUrl({ locationSale });
	const headers = getHeaders();
	let body = new FormData();
	delete headers['Content-Type'];
	if (picture) {
		body.append('intercom_picture', picture);
	} else {
		body.append('intercom_picture', '');
	}
	return {
		url: url,
		method: method,
		headers: headers,
		body: body
	};
};

const urlMethod = 'location-sales';

const getUrl = ({ locationSale }) => {
	return `${getRequestUrl(urlMethod)}/${locationSale.id}/`;
};

const getHeaders = () => {
	return getRequestHeaders();
};

const checkErrors = (response) => {
	if (response && response.code) {
		switch (response.code) {
		default:
			throw new ServiceError(response);
		}
	}
	return response;
};
