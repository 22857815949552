import { doRequest, getRequestBody, getRequestUrl, getRequestHeaders } from './utils/request.js';
import { ServiceError } from './utils/errors.js';

export const uploadContract = ({ locationSale, contract, installationPlacement }) => {
	const method = 'PUT';
	const { url, headers, body } = getData({ locationSale, contract, installationPlacement }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

export const deleteContract = ({ locationSale }) => {
	const method = 'PUT';
	const { url, headers, body } = getData({ locationSale }, method);
	return doRequest(url, method, headers, body)
		.then(checkErrors);
};

export const getContractTemplate = ({ locationSale }) => {
	const method = 'GET';
	const { url, headers, body, preParse} = getContractTemplateData({locationSale}, method);
	return doRequest(url, method, headers, body, preParse)
		.then(checkErrors);
};


/* Utility functions */

const getData = ({ locationSale, contract, installationPlacement }, method) => {
	const url = getUrl({ locationSale });
	const headers = getHeaders();
	let body = new FormData();
	delete headers['Content-Type'];
	if (contract) {
		body.append('contract', contract);
	} else {
		body.append('contract', '');
	}
	if (installationPlacement) {
		body.append('installation_placement', installationPlacement);
	}
	return {
		url: url,
		method: method,
		headers: headers,
		body: body
	};
};

const getContractTemplateData = ({locationSale}, method, body) => {
	const url = getUrl({ locationSale }) + '/contract';
	return {
		url: url,
		method: method,
		headers: getHeaders(),
		body: body ? getBody(body) : null
	};
};

const urlMethod = 'location-sales';

const getUrl = ({ locationSale }) => {
	return `${getRequestUrl(urlMethod)}/${locationSale.id}`;
};

const getHeaders = () => {
	return getRequestHeaders();
};

const getBody = (body) => {
	return getRequestBody(body);
};

const checkErrors = (response) => {
	if (response && response.code) {
		switch (response.code) {
		default:
			throw new ServiceError(response);
		}
	}
	return response;
};
