import { css } from 'lit-css';

export default css`
:host {
	opacity: 0;
	position: absolute;
	display: block;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 90;
	height: 100vh;
	width: 100vw;
	margin-top: -87px;
	transition: all 0.4s ease-in-out;
	display: flex;
	margin-right: 0;
	transform: translateX(100vw);
}

:host([active]) {
	opacity: 1;
}

:host([open]) {
	transform: translateX(0);
}

.content {
	box-sizing: border-box;
	background: var(--primary-background-color);
	padding: 24px;
	flex: 100% 0 0;
	position: relative;
}

.graphics {
	position: relative;
	display: block;
	text-align: center;
	margin-top: 10vh;
	height: 290px;
	width: 290px;
	left: 50%;
	margin-left: -145px;
}

.bubble {
	background-color: rgba(50, 50, 50, 0.2);
	height: 100%;
	width: 100%;
	border-radius: 50%;
}

.image {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.link {
	@apply --font-common-base;
	color: var(--accent-color);
	position: absolute;
	top: 42px;
	left: 42px;
	cursor: pointer;
}

.title {
	@apply --font-common-title;
	position: relative;
	margin-bottom: 19px;
	color: var(--dark-theme-divider-color);
	font-size: 36px;
	margin-top: 19px;
}

.description {
	@apply --font-common-base;
	font-size: 18px;
	line-height: 22px;
	color: var(--dark-theme-divider-color);
}

/* Tablet*/
@media (min-width: 890px) {
	:host {
		margin-top: -117px;
		height: 200vh;
		width: 50vw;
		flex: 1 0 0;
	}

	:host([open]) {
		transform: translateX(50vw);
	}

	.content {
		display: block;
		flex: 5 0 0;
	}

	.link {
		left: 10px;
	}
}

/* Desktop */
@media (min-width: 1366px) {
	:host {
		display: block;
		flex: 3 0 0;
		position: relative;
		z-index: 1;
		height: auto;
		width: auto;
		margin-top: 0;
		left: 0;
		transform: translateX(0);
	}

	:host([open]) {
		transform: translateX(0);
	}

	.content {
		background-color: transparent;
	}

	.graphics {
		margin-top: -20px;
		width: 100%;
	}

	.title, .description {
		color: var(--light-theme-background-color);
	}

	.link {
		display: none;
	}

	.bubble {
		background-color: rgba(250,250,250,0.2);
	}
}
`;
