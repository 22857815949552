import { css } from 'lit-css';
import baseStyles from './styles/lit/base-styles.js';
import { AppStyles } from './styles/app-styles.js';
import { SharedStyles } from './styles/shared-styles.js';
import { ButtonStyles } from './styles/button-styles.js';
import lavaBubbleAnimation from './styles/lit/animations/lava-bubble-animation.js';

export default css`
${baseStyles}
${SharedStyles}
${ButtonStyles}
${AppStyles}
${lavaBubbleAnimation}
`;
