export const AppStyles = `
	:host([main-page]) .access {
		visibility: hidden;
	}

	:host {
		display: block;
		--app-background-color: var(--accent-color);
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		background-color: var(--app-background-color);
		--page-indicator-primary-color: var(--app-background-color);
		--page-indicator-secondary-color: var(--primary-background-color);
		--page-height: calc(100vh - 268px);
		--access-panel-opacity: 1;
	}

	:host([page="error"]), :host([page="dismiss"]) {
		--app-background-color: var(--error-color);
	}

	:host([page="success"]) {
		--app-background-color: var(--primary-color);
	}

	:host {
		transition: background 0.5s ease-in-out;
	}
	.menu, .wrapper, .content {
		transition: all 0.4s ease-in-out;
	}

	.menu {
		height: 87px;
	}

	.menu__wrapper {
		padding: 0 24px;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
	}

	.logo {
		flex: 1 0 95px;
		display: inline-block;
		height: 24px;
		width: 95px;
		fill: var(--primary-text-color);
	}

	.spacer {
		width: 100%;
	}

	.link {
		color: var(--primary-background-color);
		text-decoration: none;
		border-bottom: 1px solid;
		font-weight: bold;
		margin-top: 10px;
		font-size: 14px;
		cursor: pointer;
	}

	.info-button {
		position: relative;
		margin: 10px 0 0 27px;
		padding: 0;
		background: none;
		border: none;
		cursor: pointer;
		outline: none;
	}

	.info-button__background {
		animation: lava-bubble-animation 15s infinite linear;
		-webkit-animation: lava-bubble-animation 15s infinite linear;
	}

	.info-button__text {
		color: var(--accent-color);
		text-decoration: none;
		position: absolute;
		top: 10px;
		left: 17px;
		font-size: 18px;
		line-height: 22px;
		font-style: italic;
		font-weight: 700;
	}


	.wrapper {
		height: calc(100vh - 87px);
		box-sizing: border-box;
		position: relative;
	}

	.content {
		background-color: var(--primary-background-color);
		display: flex;
		flex-direction: column;
		height: 100vh;
	}

	.page {
		height: var(--page-height);
		margin: 0;
		box-sizing: border-box;
		overflow: auto;
		padding: 0 24px;
	}

	.buttons {
		padding: 17px;
		text-align: center;
		box-sizing: border-box;
	}

	.dismiss-location {
		margin: -20px 0 30px 0;
	}

	.dismiss-location .link {
		color: var(--primary-text-color);
	}

	.button--go-back {
		margin-right: 23px;
	}

	.page-indicator {
		margin-bottom: 16px;
		width: 200px;
		height: 12px;
		margin-left: auto;
		margin-right: auto;
	}

	.scrim {
		opacity: 0.5;
		transform: translateX(-100vw);
		pointer-events: none;
    }

	/* Tablet */
	@media (min-width: 890px) {
		:host {
			--app-background-color: var(--accent-color);
			padding-bottom: 61px;
			box-sizing: border-box;
			min-height: 821px;
			--page-indicator-primary-color: var(--primary-background-color);
			--page-indicator-secondary-color: var(--app-background-color);
		}

		:host([main-page]) .access {
			visibility: visible;
		}

		.menu {
			height: 116px;
		}
		.menu__wrapper {
			padding: 0 40px;
			max-width: 1440px;
			margin: 0 auto;
		}
		.logo {
			height: 22px;
			width: 91px;
		}

		.wrapper {
			padding: 0 40px;
			max-width: 1440px;
			margin: 0 auto;
		}

		.content {
			overflow: hidden;
			flex-wrap: wrap;
			border-radius: 7px;
			height: 591px;
		}

		.page {
			box-sizing: border-box;
			padding: 0 43px;
			flex: 0 100%;
			width: 62.5%;
			height: 100%;
			background-color: var(--citibox-pure-white);
		}

		:host([main-page]) .access, .access {
			display: block;
			width: 37.5%;
			height: 430px;
			box-sizing: border-box;
			padding: 60px 40px 0 40px;
			background-color: var(--citibox-lighty);
			position: relative;
			margin: 0;
			padding: 60px 40px 0 40px;
			bottom: auto;
			left: auto;
			transform: translateY(0);
			border-radius: 0;
		}

		.access__bar {
			display: none;
		}

		.access__tap-zone {
			display: none;
		}

		.access__title {
			color: var(--dark-theme-disabled-color);
			font-size: 28px;
			line-height: 32px;
		}

		.access__list {
			max-height: calc(100% - 33px);
			position: relative;
			z-index: 1;
			overflow: auto;
			background: var(--citibox-lighty) no-repeat;
			background-image: -webkit-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -webkit-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
			background-image: -moz-radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), -moz-radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
			background-image: radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
			background-position: 0 0, 0 100%;
			background-size: 100% 14px;
		}

		.access__list:before,
		.access__list:after {
			content: "";
			position: relative;
			z-index: -1;
			display: block;
			height: 30px;
			margin: 0 0 -30px;
			background: -webkit-linear-gradient(top, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
			background: -moz-linear-gradient(top, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
			background: linear-gradient(to bottom, var(--citibox-lighty), var(--citibox-lighty) 30%, rgba(255, 255, 255, 0));
		}

		.access__list:after {
			margin: -30px 0 0;
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--citibox-lighty) 70%, var(--citibox-lighty));
		}

		.access__link {
			color: var(--dark-theme-divider-color);
		}

		.access__type {
			color: var(--dark-theme-disabled-color);
		}

		.access__address {
			color: var(--primary-color);
		}

		.access__region {
			color: var(--light-theme-secondary-color);
		}

		.access__delete {
			stroke: var(--light-theme-secondary-color);
		}

		.buttons {
			display: block;
			height: 160px;
			padding: 42px;
			background-color: var(--citibox-lighty);
		}

		.page-indicator {
			width: 413px;
			height: 19px;
			position: absolute;
			top: 633px;
			left: 50%;
			margin-left: -206.5px;
		}



		.scrim {
			transform: translateX(-50vw);
		}

	}

	/* Desktop */
	@media (min-width: 1366px) {
		:host {
			padding-bottom: 135px;
		}
		.menu {
			height: 132px;
		}
		.info-button {
			display: none;
		}
		.wrapper {
			display: flex;
			justify-content: space-between;
		}
		.content {
			margin-left: 8.33%;
			flex: 8 0 0;
		}

		.page-indicator {
			margin-right: 0;
			margin-left:25%;
			position: absolute;
			left: 24px;
		}

		.scrim {
			opacity: 1;
			transform: translateX(0);
		}
	}
}`;
