export const setAuthData = (authData = null) => {
	if (authData) {
		localStorage.setItem('auth', JSON.stringify(authData));
	} else {
		localStorage.removeItem('auth');
	}
	return authData;
};

export const getAuthData = () => {
	const authData = localStorage.getItem('auth');
	return (authData ? JSON.parse(authData) : null);
};
