export const RESET_PROSPECTION = 'RESET_PROSPECTION';
export const GET_PROSPECTION = 'GET_PROSPECTION';
export const SET_PROSPECTION = 'SET_PROSPECTION';
export const GET_PROSPECTION_ADDRESSES = 'GET_PROSPECTION_ADDRESSES';
export const SET_PROSPECTION_ADDRESSES = 'SET_PROSPECTION_ADDRESSES';
export const UPDATE_PROSPECTION = 'UPDATE_PROSPECTION';
export const ADD_PLACEMENT_PICTURES = 'ADD_PLACEMENT_PICTURES';
export const REMOVE_PLACEMENT_PICTURE = 'REMOVE_PLACEMENT_PICTURE';
export const SET_PROSPECTION_INTERCOM_PICTURE = 'SET_PROSPECTION_INTERCOM_PICTURE';
export const SET_PROSPECTION_CONTRACT = 'SET_PROSPECTION_CONTRACT';
export const ADD_CONTRACT_FAILED = 'ADD_CONTRACT_FAILED';
export const GET_PROSPECTION_CONTRACT_TEMPLATE = 'GET_PROSPECTION_CONTRACT_TEMPLATE';
export const GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS = 'GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS';
export const GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED = 'GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED';
export const UPDATE_DISMISS_REASON = 'UPDATE_DISMISS_REASON';
export const PROSPECTION_UPDATE_CONTACT_PERSON = 'PROSPECTION_UPDATE_CONTACT_PERSON';
export const PROSPECTION_DELETE_CONTACT_PERSON = 'PROSPECTION_DELETE_CONTACT_PERSON';

import { getLocationSale, putLocationSaleContact, postLocationSalesContact, postDismissReason, getEntrances } from '../services/locationSales.js';
import { putAddress } from '../services/addresses.js';
import { uploadPlacementPictures, getPlacementPictures as getPlacementPicturesService, deletePlacementPicture } from '../services/placementPictures.js';
import { uploadIntercomPicture, deleteIntercomPicture } from '../services/intercomPicture.js';
import { getContractTemplate as getContractTemplateService, uploadContract, deleteContract } from '../services/contract.js';
import { handleProspectionError } from './app.js';

const fetchLocationSale = (id, dispatch) => {
	return getLocationSale(id)
		.then((locationSale) => dispatch(getProspectionSucceeded(locationSale)))
		.catch((error) => dispatch(getProspectionFailed(error)));
};

export const updateDismissReason = ({ dismissReason, customDismissReason }) => (dispatch) => {
	dispatch({
		type: UPDATE_DISMISS_REASON,
		dismissReason,
		customDismissReason
	});
};

export const dismissProspection = () => (dispatch, getState) => {
	const { dismissReason, customDismissReason, current } = getState().prospection;
	postDismissReason(current.id, dismissReason, customDismissReason).then()
		.catch((error) => dispatch(dismissProspectionFailed(error)));
};

const dismissProspectionFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const getProspection = () => (dispatch, getState) => {
	const { current } = getState().prospection;
	dispatch({
		type: GET_PROSPECTION,
		id: current.id
	});
	if (current.id) {
		fetchLocationSale(current.id, dispatch);
	}
};


export const resetProspection = () => ({
	type: RESET_PROSPECTION
});

export const setProspection = (prospection) => ({
	type: SET_PROSPECTION,
	current: prospection
});

export const getProspectionAddresses = () => (dispatch, getState) => {
	const { current } = getState().prospection;
	dispatch({
		type: GET_PROSPECTION_ADDRESSES
	});
	getEntrances(current.id)
		.then((response) => dispatch(getProspectionAddressesSucceeded(response.results)))
		.catch((error) => dispatch(getProspectionAddressesFailed(error)));
};

export const saveEntrances = (newEntrances) => (dispatch, getState) => {
	const { entrances } = getState().prospection.current;
	entrances.forEach((entrance) => {
		const newEntrance = newEntrances.find((item) => item.id === entrance.id);
		if (newEntrance.main_entrance !== entrance.main_entrance) {
			const { id, main_entrance } = newEntrance;
			putAddress({ id, main_entrance });
		}
	});
};

export const updateContactPerson = (contactPerson) => (dispatch, getState) => {
	const prospection = getState().prospection.current;
	const sameTypePersons = prospection.contact_people && prospection.contact_people.filter(person => person.type === contactPerson.type);
	if (sameTypePersons && sameTypePersons.length > 0) {
		contactPerson.id = sameTypePersons[0].id;
	}
	dispatch({
		type: PROSPECTION_UPDATE_CONTACT_PERSON,
		contactPerson
	});
};

export const deleteContactPerson = (contactPerson) => (dispatch) => {
	dispatch({
		type: PROSPECTION_DELETE_CONTACT_PERSON,
		contactPerson: {
			id: contactPerson.id,
			type: contactPerson.type
		}
	});
};

export const saveContactPeople = async (current) => {
	if (current.contact_people && current.contact_people.length) {
		return Promise.all(
			current.contact_people
				.sort((contact) => contact.signatory ? 1 : -1)
				.map((contact) => contact.id ? 
					putLocationSaleContact(current.id, contact) : 
					postLocationSalesContact(current.id, contact)
				)
		);
	}
	return Promise.resolve();
};

export const updateProspection = (prospection) => (dispatch) => {
	dispatch({
		type: UPDATE_PROSPECTION,
		current: prospection
	});
};

const fileStringToArray = (file, isDocument) => {
	let response = file || [];
	if (typeof response === 'string') {
		response = [{
			url: file,
			id: 0,
			isUploading: false,
			isDocument: isDocument || false
		}];
	}
	return response;
};

export const getProspectionSucceeded = (locationSale) => (dispatch) => {
	locationSale.intercom_picture = fileStringToArray(locationSale.intercom_picture);
	locationSale.contract = fileStringToArray(locationSale.contract, true);
	dispatch(setProspection(locationSale));
	dispatch(getPlacementPictures(locationSale));
	dispatch(getProspectionAddresses());
};

export const getProspectionFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const setProspectionAddresses = (entrances) => ({
	type: SET_PROSPECTION_ADDRESSES,
	entrances: entrances
});

export const getProspectionAddressesSucceeded = (entrances) => (dispatch) => {
	dispatch(setProspectionAddresses(entrances));
};

export const getProspectionAddressesFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const addPlacementPictures = ({ locationSale, pictures }) => (dispatch) => {
	uploadPlacementPictures({ locationSale, pictures })
		.then(() => dispatch(getPlacementPictures(locationSale)))
		.catch((error) => dispatch(addPlacementPicturesFailed(error)));
};

export const getPlacementPictures = (locationSale) => (dispatch) => {
	getPlacementPicturesService({ locationSale })
		.then((response) => {
			const placementPictures = response.results.map((picture) => {
				return {
					...picture,
					inProgress: false
				};
			});
			dispatch(updateProspection({
				placement_pictures: [
					...placementPictures
				]
			}));
		});
};

export const addPlacementPicturesFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const removePlacementPicture = (picture) => (dispatch, getState) => {
	const locationSale = getState().prospection.current;

	deletePlacementPicture({ locationSale, picture })
		.then(() => dispatch(getPlacementPictures(locationSale)))
		.catch((error) => dispatch(removePlacementPictureFailed(error)));
};

export const removePlacementPictureFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const addIntercomPicture = (picture) => (dispatch, getState) => {
	const locationSale = getState().prospection.current;
	uploadIntercomPicture({ locationSale, picture })
		.then(() => dispatch(addIntercomPictureSuccess(locationSale)))
		.catch((error) => dispatch(addIntercomPictureFailed(error)));
};


const addIntercomPictureSuccess = (locationSale) => (dispatch) => {
	return getLocationSale(locationSale.id)
		.then((updatedLocationSale) => {
			dispatch({
				type: SET_PROSPECTION_INTERCOM_PICTURE,
				intercom_picture: fileStringToArray(updatedLocationSale.intercom_picture)
			});
		});
};
const addIntercomPictureFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const removeIntercomPicture = () => (dispatch, getState) => {
	const locationSale = getState().prospection.current;
	deleteIntercomPicture({ locationSale })
		.then(() => dispatch(removeIntercomPictureSuccess()))
		.catch((error) => dispatch(removeIntercomPictureFailed(error)));
};
const removeIntercomPictureSuccess = () => (dispatch) => {
	dispatch({
		type: SET_PROSPECTION_INTERCOM_PICTURE,
		intercom_picture: []
	});
};

const removeIntercomPictureFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};

export const addContract = (contract) => (dispatch, getState) => {
	const locationSale = getState().prospection.current;
	uploadContract({ locationSale, contract, installationPlacement: locationSale.installation_placement })
		.then(() => fetchLocationSale(locationSale.id, dispatch))
		.catch((error) => dispatch(addContractFailed(error)));
};


const addContractFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
	dispatch({
		type: ADD_CONTRACT_FAILED
	});
};

export const removeContract = () => (dispatch, getState) => {
	const locationSale = getState().prospection.current;
	deleteContract({ locationSale })
		.then(() => fetchLocationSale(locationSale.id, dispatch))
		.catch((error) => dispatch(removeContractFailed(error)));
};

const removeContractFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
};


const setContractTemplate = (contractTemplate) => ({
	type: GET_PROSPECTION_CONTRACT_TEMPLATE_SUCCESS,
	contractTemplate
});

export const getContractTemplate = () => (dispatch, getState) => {
	const state = getState();
	if (state.prospection.current.contractTemplate) {
		dispatch(setContractTemplate(state.prospection.current.contractTemplate));
	} else {
		dispatch({ type: GET_PROSPECTION_CONTRACT_TEMPLATE });
		getContractTemplateService({ locationSale: state.prospection.current })
			.then((response) => dispatch(setContractTemplate(response.contract)))
			.catch((error) => dispatch(getContractTemplateFailed(error)));
	}
};



const getContractTemplateFailed = (error) => (dispatch) => {
	dispatch(handleProspectionError(error));
	dispatch({ type: GET_PROSPECTION_CONTRACT_TEMPLATE_FAILED });
};


export const updateBuildingType = (building_type) => (dispatch) => {
	dispatch(updateProspection({ building_type }));
};

export const updateHouseholdsNumber = (households_number) => (dispatch) => {
	dispatch(updateProspection({ households_number }));
};

export const updateBoxNumber = (box_number) => (dispatch) => {
	dispatch(updateProspection({ box_number }));
};

export const updateBoxModel = (box_model) => (dispatch) => {
	dispatch(updateProspection({ box_model }));
};

export const updateHasDoorman = (has_doorman) => (dispatch) => dispatch(updateProspection({ has_doorman }));

export const updateDoorman = (key, value) => (dispatch) => dispatch(updateContactPerson({
	type: 'doorman',
	[key]: value
}));
