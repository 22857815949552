const GOOGLE_ANALYTICS_PROPERTY_ID = window.process.env.GOOGLE_ANALYTICS_PROPERTY_ID || '';

// Allow async usage of GA
window.ga = window.ga || function(){( window.ga.q = window.ga.q || []).push(arguments);}; 
window.ga.l=+new Date;

// https://developers.google.com/analytics/devguides/collection/analyticsjs/?hl=es-419
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
	(i[r].q=i[r].q||[]).push(arguments);},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
window.ga('create', GOOGLE_ANALYTICS_PROPERTY_ID, 'auto');
window.ga('set', 'dimension1', 'online');

export const trackPage = (page) => {
	window.ga('set', 'page', page);
	window.ga('send', 'pageview');
};

export const track = (parameters = {}) => {
	const {
		category = '',
		action = '',
		label = '',
		value,
		fields = null,
	} = parameters;
	window.ga('send', 'event', category, action, label, value, fields);
};