import { LitElement, html } from '@polymer/lit-element';

class SnackBar extends LitElement {
	render() {
		return html`
      <style>

      :host {
        @apply --font-body2;
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px;
        background-color: var(--accent-color);
        color: var(--primary-background-color);
        border-radius: 5px 5px 0 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        will-change: transform;
        transform: translate3d(0, 100%, 0);
        transition-property: visibility, transform;
        transition-duration: 0.2s;
        visibility: hidden;
        margin-bottom: 0;
      }

      :host([active]) {
        visibility: visible;
        transform: translate3d(0, 0, 0);
      }

      @media (min-width: 460px) {
        :host {
          width: 320px;
          margin-left: auto;
          margin-right: auto;
          background-color: var(--light-theme-background-color);
          color: var(--primary-text-color);
        }
      }
    </style>
    <slot></slot>
    `;
	}

	static get properties() { return {
		active: { type: Boolean },
	};}
}

window.customElements.define('snack-bar', SnackBar);
